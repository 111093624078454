import notifier, { NotifierService } from '../Notifier/Notifier';
import Vue from 'vue';
import Guid from '../Guid';
import { SnotifyEvent, SnotifyToast } from 'vue-snotify';

import ErrorNotification from '@/shared/components/ApiErrorNotification.vue';
import { AxiosErrorOutput } from './AxiosErrorOutput';
import { AxiosError } from 'axios';
import { ApiError } from './ApiError';

export class AxiosErrorNotification implements AxiosErrorOutput {
    public constructor(private _notifier: NotifierService) {}

    public displayError(error: AxiosError<ApiError> | ApiError): void {
        const parsed = 'isAxiosError' in error ? this.parseAxiosError(error) : error;
        const containerId = `err_${Guid.NewGuid()
            .split('-')
            .join('_')}`;
        const events = this.buildPopupCallbacks(parsed, containerId);
        this._notifier.showError(`<div id="${containerId}"></div>`, 'Attention', 30000, events);
    }

    private parseAxiosError(error: AxiosError<ApiError>): ApiError {
        return {
            url: error.config.url || '',
            method: error.config.method as string,
            statusCode: error.response?.status,
            message: error.response?.data.message,
            timestamp: new Date().toUTCString(),
        };
    }

    private buildPopupCallbacks(model: ApiError, containerId: string): Map<SnotifyEvent, (toast: SnotifyToast) => void> {
        let intance: Vue;
        if (!model.message) {
            model.message = 'Could not get any response';
        }
        if (!model.method) {
            model.method = 'URL';
        } else {
            model.method = 'HTTP ' + model.method;
        }
        const events = new Map([
            [
                'mounted' as SnotifyEvent,
                (toast: SnotifyToast) => {
                    intance = new ErrorNotification({
                        propsData: {
                            model: model,
                            close: () => {
                                this._notifier.$snotify.remove(toast.id);
                            },
                        },
                    });
                    intance.$mount(`#${containerId}`);
                },
            ],
            [
                'hidden' as SnotifyEvent,
                (toast: SnotifyToast) => {
                    intance.$destroy();
                },
            ],
        ]);
        return events;
    }
}

export default new AxiosErrorNotification(notifier);
