import axios, { AxiosError } from 'axios';
import { AxiosErrorOutput } from './AxiosErrorOutput';
import qs from 'qs';
import VueRouter from 'vue-router';
import { AuthService } from '../auth/AuthService';

export class AxiosInterceptorsConfig {
    private _errorMessageOutput: AxiosErrorOutput;

    public constructor(errorMessageOutput: AxiosErrorOutput) {
        this._errorMessageOutput = errorMessageOutput;
    }

    public setupInterceptors(router: VueRouter): void {
        axios.interceptors.request.use(request => {
            request.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });
            return request;
        });
    }

    public setupAuthInterceptors(router: VueRouter, authService: AuthService) {
        axios.interceptors.request.use(async req => {
            const token = await authService.getToken();
            if (!!token) req.headers.Authorization = `Bearer ${token}`;
            return req;
        });
        axios.interceptors.response.use(
            response => {
                return new Promise((resolve, reject) => {
                    resolve(response);
                });
            },
            (error: AxiosError) => {
                if (error.response && error.response.data instanceof Blob && error.response.data.type === 'application/json') {
                    const reader = new FileReader();
                    reader.readAsText(error.response.data, 'utf-8');
                    reader.onloadend = () => {
                        if (reader.result && typeof reader.result === 'string') {
                            this._errorMessageOutput.displayError(JSON.parse(reader.result));
                        }
                    };
                    reader.onerror = () => {
                        this._errorMessageOutput.displayError(error);
                    };
                } else {
                    this._errorMessageOutput.displayError(error);
                }
                if (error.response?.status === 401) {
                    authService.logOut(true).then(() => {
                        router.push('/login');
                    });
                }
                return Promise.reject(error);
            }
        );
    }
}
