











import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            classes: null,
        };
    },
    computed: {
        showAdminLink() {
            return !!this.$auth.claims?.Admin && !this.$route.name?.includes('editor');
        },
    },
    mounted() {
        this.classes = this.$route.meta.contentClass;
    },
    beforeRouteUpdate(to, from, next) {
        this.classes = to.meta.contentClass || null;
        next();
    },
});
