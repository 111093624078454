
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import EditBackground from './EditBackground.vue';
import PickLogo from './PickLogo.vue';
import { Banner, BannerResources } from '@models';
import { TemplateField } from '../models/TemplateFields';
import EditorField from './EditorField.vue';
import EditorBackgroundAlignment from './EditorBackgroundAligment.vue';
import { createHash } from '@/shared/utils';
import { getUniqValuesByKey, getUniqValuesByKeys } from '../utils';
import { WidgetBase } from '@/packages/template-renderer/types/Widget';
import { TemplateModelField } from '@/shared/models/Template';
import { FamiliesType } from '@shared/models/FamiliesType';

@Component({
    components: {
        EditBackground,
        PickLogo,
        EditorField,
        EditorBackgroundAlignment,
    },
})
export default class EditPanel extends Vue {
    @Prop({ default: false, type: Boolean }) allowSave!: boolean;
    @Prop({ default: () => [], type: Array }) families!: FamiliesType[];
    @Prop({ default: false, type: Boolean }) disabled!: boolean;
    @Prop({ default: () => [], type: Array }) selected!: Banner[];
    @Prop({ required: false, default: () => ({ icons: [], fonts: [] }) }) resources!: BannerResources;

    get mergedModel() {
        const model: {
            backgroundSize?: string;
            backgroundPosition?: string;
            colorPalette: string[];
            fontSizes: number[];
            fields: {
                [key: string]: Record<string, any>;
            };
        } = {
            fields: {},
            colorPalette: [
                ...new Set(
                    this.selected.reduce(
                        (acc, cur) => acc.concat(cur.template.contentSchema.colorPalette ? cur.template.contentSchema.colorPalette : []),
                        [] as string[]
                    )
                ),
            ],
            fontSizes: [
                ...new Set(
                    this.selected.reduce(
                        (acc, cur) => cur.template.contentSchema.fontSizes && acc.concat(cur.template.contentSchema.fontSizes),
                        [] as number[]
                    )
                ),
            ],
        };
        if (!model.colorPalette.length) {
            model.colorPalette = ['#3b4747'];
        }
        const models = this.selected.map(selected => selected.model);
        const bgSizes = getUniqValuesByKey(models, 'backgroundSize');
        const bgPositions = getUniqValuesByKey(models, 'backgroundPosition');
        model.backgroundSize = bgSizes.length === 1 ? (bgSizes[0] as string) : '';
        model.backgroundPosition = bgPositions.length === 1 ? (bgPositions[0] as string) : '';
        for (const formField of this.fields) {
            model.fields[formField.id] = { label: formField.label, hidden: false, value: '' };
            const models = this.selected.reduce(
                (acc, banner) => acc.concat(banner.model.fields.filter(field => formField.label === field.label)),
                [] as TemplateModelField[]
            );
            const widgets = this.selected.reduce(
                (acc, banner) => acc.concat(banner.template.contentSchema.widgets.filter(widget => widget.label === formField.label)),
                [] as WidgetBase[]
            );
            const uniqValues = getUniqValuesByKeys(models);
            const uniqDefaultValues = getUniqValuesByKey(widgets, 'defaultValue');
            const hasValue = uniqValues.value?.length;
            if (!hasValue) {
                model.fields[formField.id].value = uniqDefaultValues?.length === 1 ? uniqDefaultValues[0] : '';
            }
            for (const key of Object.keys(uniqValues)) {
                if (uniqValues[key].length) {
                    model.fields[formField.id][key] = uniqValues[key].length === 1 ? uniqValues[key][0] : '';
                }
            }
        }
        return model;
    }

    get imageSrc() {
        const images = new Set([...this.selected.map(b => b.backgroundImage).filter(Boolean)]);
        if (images.size === 1) {
            return [...images][0];
        } else {
            const defaults = [...new Set(this.selected.map(b => b.template.background?.image?.defaultUrl).filter(Boolean))];
            return defaults.length === 1 ? defaults[0] : '';
        }
    }

    get fields() {
        const fields = [] as TemplateField[];
        const uniqFields = new Set();
        for (const banner of this.selected) {
            for (const widget of banner.template.contentSchema.widgets) {
                if (!widget.label || widget.type === 'layer') continue;
                if (!uniqFields.has(widget.label)) {
                    uniqFields.add(widget.label);
                    fields.push({
                        label: widget.label,
                        id: createHash(widget.label).toString(),
                        type: widget.type === 'icon' ? 'icon' : widget.type === 'button' ? 'input' : 'textarea',
                        isColorized: false,
                        optional: false,
                        order: uniqFields.size,
                    });
                }
            }
        }
        return fields;
    }

    get iconsFields() {
        return this.fields?.filter(f => f.type === 'icon');
    }

    get inputFileds() {
        return this.fields?.filter(f => f.type === 'textarea' || f.type === 'input');
    }
}
