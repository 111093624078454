












import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import Component from 'vue-class-component';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import { Editor } from '@toast-ui/vue-editor';
import { EditorOptions } from '@toast-ui/editor';
import fontSizePlugin from './TueEditor/plugins/fontSize';
import EditorFiledMixin from './EditorFiledMixin.vue';
import { Ref, Prop, Watch } from 'vue-property-decorator';
import fontWeightPlugin from './TueEditor/plugins/fontWeight';

@Component({
    components: {
        editor: Editor,
    },
    model: {
        prop: 'value',
        event: 'change',
    },
})
export default class TueEditor extends EditorFiledMixin {
    @Ref('editor') editor!: Editor;
    @Prop() fontSizes!: number[];
    fontWeight = [400, 500, 600];

    preventChangeEvent = false;

    get options(): Partial<EditorOptions> {
        return {
            usageStatistics: false,
            plugins: [[colorSyntax, { preset: this.palette }] as any, [fontSizePlugin, { preset: this.fontSizes }], [fontWeightPlugin, {preset: this.fontWeight}]],
            previewStyle: undefined,
            hideModeSwitch: false,
            initialEditType: 'wysiwyg',
            toolbarItems: ['bold', 'italic'],
        };
    }

    onChange(...args: any[]) {
        if (!this.preventChangeEvent) {
            this.$emit('change', { value: this.getHtml() });
        } else {
            this.preventChangeEvent = false;
        }
    }

    getHtml() {
        return this.editor.invoke('getHtml');
    }

    /* dirty hack to prevent editor send change event when we manualy setHtml */
    @Watch('model.value')
    onModelValueChanged(value: any) {
        setTimeout(() => {
            if (value !== this.getHtml()) {
                this.preventChangeEvent = true;
                this.editor.invoke('setHtml', value);
            }
        });
    }
}
