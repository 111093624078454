import { Module } from 'vuex';
import { ListPageApiAdapter } from '@api';
import { ListPageState, PageResult, ListFilterParams } from '@/shared/models';
import { StateChanger } from 'vue-infinite-loading';

export const PAGINATION_MUTATIONS = {
    setData: 'SET_PAGE_DATA',
    deleteItems: 'DELETE_PAGE_DATA',
    setParams: 'SET_PAGE_PARAMS',
    resetParams: 'RESET_PAGE_PARAMS',
    resetData: 'RESET_PAGING',
    reset: 'RESET',
    setLoading: 'SET_LOADING',
} as const;

export const PAGINATION_ACTIONS = {
    load: 'LOAD_PAGE_DATA',
    updateParams: 'UPDATE_PAGE_PARAMS',
    updatePageSize: 'UPDATE_PAGE_SIZE',
} as const;

export interface PaginationLoadStatus {
    loaded: boolean;
    allFetched?: boolean;
}

export interface PadinationLoadOptions {
    reload?: boolean;
    page?: number;
    scrollEvent?: StateChanger;
}

export interface PaginationState<P extends ListFilterParams, M> extends ListPageState<P, M> {
    loading?: boolean;
    allFetched?: boolean;
}

export interface PaginationModuleOptions<P extends ListFilterParams, M> {
    api: ListPageApiAdapter<P, M>;
    initialState: () => PaginationState<P, M>;
    namespaced?: boolean;
    infinite?: boolean;
}

export default function createPaginationModule<P extends ListFilterParams, M>({
    api,
    initialState,
    namespaced = true,
    infinite = true,
}: PaginationModuleOptions<P, M>): Module<PaginationState<P, M>, RootState> {
    function getInitialState(): PaginationState<P, M> {
        return {
            ...initialState(),
            loading: false,
            allFetched: false,
        };
    }
    return {
        state: getInitialState(),
        getters: {
            pageSize(state) {
                return state.page ? state.page.totalItems : 0;
            },
            pageItems(state) {
                return state.page ? state.page.rows : [];
            },
            dataId(state) {
                return state.dataId;
            },
            total(state) {
                return state.page.totalItems;
            },
            pageIndex(state) {
                return state.page?.index;
            },
        },
        actions: {
            async [PAGINATION_ACTIONS.load](
                { commit, state: { page, params: filter, allFetched } },
                options?: PadinationLoadOptions
            ): Promise<any> {
                if (options && options.reload) {
                    commit(PAGINATION_MUTATIONS.resetData);
                    return true;
                }
                if (!infinite || (infinite && !allFetched)) {
                    const isFirstRequest = !allFetched && !page.rows.length;
                    commit(PAGINATION_MUTATIONS.setLoading, true);
                    try {
                        const res = await api.getPage({
                            index: isFirstRequest ? 0 : options?.page == undefined ? page.index + 1 : options.page,
                            params: filter,
                            size: page.size,
                        });
                        commit(PAGINATION_MUTATIONS.setData, res);
                        if (options?.scrollEvent) {
                            if (res.rows.length) {
                                options.scrollEvent.loaded();
                            }
                            if (!res.rows.length || page.rows.length + res.rows.length === res.totalItems) {
                                options.scrollEvent.complete();
                            }
                        }
                        return !!res.rows.length;
                    } catch (error) {
                        /* TODO: handle or refetch */
                    } finally {
                        commit(PAGINATION_MUTATIONS.setLoading, false);
                    }
                }
                options?.scrollEvent?.loaded();
                options?.scrollEvent?.complete();
            },
            async [PAGINATION_ACTIONS.updateParams]({ commit, state, dispatch }, params: Partial<P>) {
                commit(PAGINATION_MUTATIONS.setParams, params);
                dispatch(PAGINATION_ACTIONS.load, infinite ? { reload: true } : { page: 0 });
            },
            async [PAGINATION_ACTIONS.updatePageSize]({ commit, state, dispatch }, size: number) {
                state.page.size = size;
                dispatch(PAGINATION_ACTIONS.load, infinite ? { reload: true } : { page: 0 });
            },
        },
        mutations: {
            [PAGINATION_MUTATIONS.setData](state, payload: PageResult<M>) {
                const { rows, ...pageMeta } = payload;
                state.allFetched = pageMeta.index * pageMeta.size + rows.length >= pageMeta.totalItems;
                state.page = infinite ? { ...pageMeta, rows: state.page.rows.concat(rows) } : { ...pageMeta, rows };
            },
            [PAGINATION_MUTATIONS.resetData](state) {
                state.page.totalItems = 0;
                state.page.rows = [];
                state.page.index = 0;
                state.dataId += 1;
                state.allFetched = false;
            },
            [PAGINATION_MUTATIONS.setParams](state, payload: Partial<P>) {
                Object.assign(state.params, payload);
            },
            [PAGINATION_MUTATIONS.resetParams](state) {
                state.params = Object.assign(state.params, getInitialState().params);
            },
            [PAGINATION_MUTATIONS.deleteItems](state, { ids, key = 'id' }: { ids: number[]; key: string }) {
                state.page.rows = state.page.rows.filter(i => key in i && !ids.includes((i as any)[key]));
                state.page.totalItems--;
            },
            [PAGINATION_MUTATIONS.reset](state) {
                const initial = getInitialState();
                const { query, sort, sortAsc } = state.params;
                Object.assign(state, {
                    ...initial,
                    params: { ...initial.params, query, sort, sortAsc },
                    page: { ...initial.page, size: state.page.size },
                });
            },
            [PAGINATION_MUTATIONS.setLoading](state, payload: boolean) {
                state.loading = payload;
            },
        },
        namespaced: namespaced,
    };
}
