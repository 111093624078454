









import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chrome } from 'vue-color';

@Component({
    components: {
        ChromeColorPicker: Chrome,
    },
})
export default class ColorPicker extends Vue {
    @Prop({ default: '#5A5A5A', required: false, type: String }) value!: string;
}
