




import { Component } from 'vue-property-decorator';
import { IconWidget } from '../types/Widget';
import BaseContainer from './BaseContainer.vue';
import IconTemplate from './IconTemplate.vue';
@Component({
    components: { IconTemplate },
})
export default class TemplateIcon extends BaseContainer<IconWidget> {

    get style() {
        return {
            ...this.getBaseWidgetStyles(),
            fill: this.schema.fill,
        };
    }
}
