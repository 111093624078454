import { PluginFunction, PluginObject } from 'vue';
import { AuthTokenProvider } from './AuthTokenProvider';
import { AuthService } from './AuthService';
import { AuthLSTokenProvider } from './LocalStorageTokenProvider';
import { AuthApiProvider } from './AuthApiProvider';
import { tokenAdapter } from '../api/adapters';
interface AuthServiceOptions {
    tokenProvider: AuthTokenProvider;
    apiProvider: AuthApiProvider;
}

interface InstallFn extends PluginFunction<AuthServiceOptions> {
    installed?: boolean;
}

const install: InstallFn = function(Vue) {
    if (install.installed) return;
    install.installed = true;

    Vue.mixin({
        beforeCreate: function(this: Vue) {
            const options = this.$options;
            if (options.auth) {
                this.$auth = options.auth;
            } else if (options.parent && options.parent.$auth) {
                this.$auth = options.parent.$auth;
            }
        },
    });
};

const plugin: PluginObject<AuthServiceOptions> = {
    install,
};

const authService = new AuthService(new AuthLSTokenProvider(), tokenAdapter);

export default plugin;
export { authService, AuthService, AuthServiceOptions };
