import { DirectiveOptions, VNode } from 'vue';

function syncScrollHeight(el: HTMLElement, node: VNode) {
    el.style.height = 'auto !important';
    node.context?.$nextTick(() => {
        el.style.height = el.scrollHeight + 'px';
    });
}

const callbacksMap = new Map<HTMLElement, () => void>();

export default {
    bind(el, binding, node) {
        el.style.overflowY = 'hidden';
        el.style.resize = 'none';
        syncScrollHeight(el, node);
        const callback = syncScrollHeight.bind(el, el, node);
        callbacksMap.set(el, callback);
        el.addEventListener('input', callback);
    },
    unbind(el) {
        const callback = callbacksMap.get(el);
        if (callback) {
            el.removeEventListener('input', callback);
            callbacksMap.delete(el);
        }
    },
} as DirectiveOptions;
