


















import { Vue, Component, Prop } from 'vue-property-decorator';

export interface AsideMenuItem {
    text: string;
    icon: string;
    path?: string;
    static?: boolean;
}

@Component
export default class AsideMenu extends Vue {
    @Prop({ default: [] })
    items!: AsideMenuItem[];
}
