










import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseTemplate from './BaseTemplate.vue';
import InlineSvg from 'vue-inline-svg';
@Component({
    components: { InlineSvg },
})
export default class Button extends BaseTemplate {
    @Prop() icon!: { styles: Record<string, any>; value?: string };
    dataUrl = '';
    log = console.log;

    get isSvg() {
        return this.icon.value?.endsWith('.svg') ?? false;
    }

    get isLocalProtocol() {
        return location.protocol === 'file:';
    }

    get svgUrl() {
        return this.isLocalProtocol ? this.dataUrl : this.icon.value;
    }

    /*
     * vue-inline-svg cannot work with file: protocol.
     * Internal function in library check for response status code and pass only codes between 200 to 400
     * and since XMLHttpRequest returns 0 code for file: protocol
     * we need to load files to dataUrl for that case
     * TIP!!!
     * Another approach to solve this issue can be
     * - fork vue-inline-svg and modify download method
     * - implement custom solution to load and inline svg
     * - serve all assets by some web server
     * */
    @Watch('icon', { immediate: true })
    loadSvg() {
        if (this.isSvg && this.isLocalProtocol) {
            const request = new XMLHttpRequest();
            request.open('GET', this.icon.value as string, true);
            request.onload = () => {
                if ((request.status >= 200 && request.status < 400) || request.status === 0) {
                    const blob = new Blob([request.responseText], { type: 'text/plain' });
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.dataUrl = reader.result as string;
                    };
                    reader.onerror = console.error;
                    reader.readAsDataURL(blob);
                }
            };
            request.onerror = err => {
                console.error('Cannot load local file');
                console.error(err);
            };
            request.send();
        }
    }
}
