import { NavigationGuard, Route } from 'vue-router';
import { AuthService } from '@/services/auth';
function isProtectedRoute(to: Route) {
    return to.matched.some(m => m.meta?.requireAuth);
}
function isAdminRoute(to: Route) {
    return to.matched.some(m => m.meta.adminRoute);
}
export function authGuard(authService: AuthService): NavigationGuard {
    return async (to, from, next) => {
        const token = await authService.getToken();
        if (to.name === 'login' && token) {
            next('false');
        }
        if (isProtectedRoute(to)) {
            if (token && (isAdminRoute(to) ? authService.claims?.Admin : true)) {
                next();
            } else {
                next('/login');
            }
            return;
        }
        next();
    };
}
