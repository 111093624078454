

















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EditorBackgroundAlignment extends Vue {
    @Prop({ type: String }) size!: string;
    @Prop({ type: String }) position!: string;
    sizeOptions = ['auto', 'cover', 'contain'];
    positionOptions = [
        'left top',
        'center top',
        'right top',
        'left center',
        'center center',
        'right center',
        'left bottom',
        'center bottom',
        'right bottom',
    ];

    onSizeChange(size: string) {
        this.$emit(
            'change',
            size !== this.size ? { backgroundSize: size, backgroundPosition: this.positionOptions[4] } : { backgroundSize: size }
        );
    }
}
