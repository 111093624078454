






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
    @Prop() name!: string;

    get url() {
        return this.name ? require('@/assets/images/svg-sprite.svg') + '#' + this.name : null;
    }
}
