import { PageRequest, PageResult } from '@/shared/models/pagination';
import ListPageApiAdapter from '@/services/api/adapters/ListPageApiAdapter';
import endpoints from '../../endpoints';
import { UserActionPreview } from '@/modules/admin/logs/models';
import { ListFilterParams } from '@/shared/models';
import axios from 'axios';

type LogsPageRequest = PageRequest<ListFilterParams>;

export class LogsApiAdapter implements ListPageApiAdapter<ListFilterParams, UserActionPreview> {
    public constructor(private _endpoints: typeof endpoints) {}

    public async getPage(request: LogsPageRequest): Promise<PageResult<UserActionPreview>> {
        const res = await axios.post<PageResult<UserActionPreview>>(this._endpoints.logs.getPage, request);
        return Promise.resolve(res.data);
    }
}

export default new LogsApiAdapter(endpoints);
