
















import { Vue, Component } from 'vue-property-decorator';
import createFocusTrap, { FocusTrap } from 'focus-trap';

@Component
export default class ModalContainer extends Vue {
    focusTrap!: FocusTrap;
    mounted() {
        this.focusTrap = createFocusTrap(this.$el as HTMLElement, {allowOutsideClick: () => true});
        this.focusTrap.activate();
        this.onEscape = this.onEscape.bind(this);
        document.addEventListener('keyup', this.onEscape);
    }
    destroyed() {
        if (this.focusTrap) {
            this.focusTrap.deactivate();
        }
        document.removeEventListener('keyup', this.onEscape);
    }
    onEscape(event: KeyboardEvent) {
        if (event.key === 'Escape' || event.which === 27) {
            this.$emit('close');
        }
    }
}
