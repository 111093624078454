import { render, staticRenderFns } from "./MediaModal.vue?vue&type=template&id=b7c5c54c&scoped=true&"
import script from "./MediaModal.vue?vue&type=script&lang=ts&"
export * from "./MediaModal.vue?vue&type=script&lang=ts&"
import style0 from "@styles/components/popup-choose-media.css?vue&type=style&index=0&lang=css&"
import style1 from "@styles/components/choose-media.css?vue&type=style&index=1&id=b7c5c54c&scoped=true&lang=css&"
import style2 from "@styles/components/media-group.css?vue&type=style&index=2&id=b7c5c54c&scoped=true&lang=css&"
import style3 from "./MediaModal.vue?vue&type=style&index=3&id=b7c5c54c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7c5c54c",
  null
  
)

export default component.exports