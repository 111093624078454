var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner",class:("banner--" + (_vm.schema.suffix ? ((_vm.schema.suffix) + "-") : '') + "size-" + (_vm.model.width) + "x" + (_vm.model.height)),style:({
        width: ((_vm.model.width) + "px"),
        height: ((_vm.model.height) + "px"),
        backgroundImage: ("url('" + (_vm.$options.filters.assetsUrl(_vm.model.backgroundImage, _vm.assetsRoot)) + "')"),
        backgroundSize: _vm.model.backgroundSize,
        backgroundPosition: _vm.model.backgroundPosition,
        backgroundColor: _vm.schema.backgroundColor,
    })},[_c('div',{staticClass:"template__layer",staticStyle:{"position":"absolute","width":"100%","height":"100%","background-position":"0 0","background-repeat":"no-repeat","background-size":"100% auto"}}),_vm._l((_vm.schema.widgets),function(widget,index){return _c(_vm.getComponent(widget),{key:((widget.type) + "-" + index),tag:"component",attrs:{"schema":widget,"model":_vm.modelFields[widget.label],"assetsRoot":_vm.assetsRoot}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }