






import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class Button extends Vue {
    @Prop({ default: false, type: Boolean }) disabled!: boolean;
    @Prop({ default: false, type: Boolean }) rounded!: boolean;

    get classes() {
        return {
            'button--disabled': this.disabled,
            'button--rounded': this.rounded,
        };
    }
}
