const dec2hex: string[] = [];
for (let i = 0; i <= 15; i++) {
    dec2hex[i] = i.toString(16);
}

export function uuid(): string {
    let uuid = '';
    for (let i = 1; i <= 36; i++) {
        if (i === 9 || i === 14 || i === 19 || i === 24) {
            uuid += '-';
        } else if (i === 15) {
            uuid += 4;
        } else if (i === 20) {
            uuid += dec2hex[(Math.random() * 4) | (0 + 8)];
        } else {
            uuid += dec2hex[(Math.random() * 16) | 0];
        }
    }
    return uuid;
}

export function createHash(salt: string) {
    let hash = 0;
    for (let i = 0; i < salt.length; i++) {
        const chr = salt.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
