














import { Vue, Component, Prop } from 'vue-property-decorator';
import { BackgroundListFilterParams, Sorting } from '@models';

@Component
export default class SearchPanel extends Vue {
    @Prop({ type: Array }) families!: string[];
    @Prop({ type: Object }) searchParams!: BackgroundListFilterParams;
}
