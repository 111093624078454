










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FormCheckbox extends Vue {
    @Prop() title!: string;
    @Prop({ default: false }) reverse!: boolean;
}
