




















import { Vue, Component, Prop } from 'vue-property-decorator';
import { BackgroundPreview } from '../models';

@Component
export default class MediaCard extends Vue {
    @Prop({
        default: () => {
            image: {
            }
        },
    })
    model!: BackgroundPreview;
    @Prop() selected!: boolean;
}
