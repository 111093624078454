



























import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalContainer from './ModalContainer.vue';
import { SnotifyPosition } from 'vue-snotify';

interface InputConfig {
    name?: string;
    placeholder?: string;
    label?: string;
    validate?(value: string): { message: string } | null;
}

export interface DialogModalProps {
    title: string;
    question?: string;
    action?: () => Promise<void>;
    input?: InputConfig;
    applyText?: string;
    rejectText?: string;
    showClose?: boolean;
    showApply?: boolean;
    showReject?: boolean;
}

export interface DialogData {
    input: string;
}

@Component({
    components: {
        ModalContainer,
    },
})
export default class DialogModal extends Vue {
    @Prop({ default: 'title' }) title!: string;
    @Prop() question!: string;
    @Prop({ required: false }) action!: () => Promise<void>;
    @Prop({ type: Object, required: false }) input!: InputConfig;
    @Prop({ default: 'YES', type: String }) applyText!: string;
    @Prop({ default: 'NO', type: String }) rejectText!: string;
    @Prop({ default: true, type: Boolean }) showClose!: boolean;
    @Prop({ default: true, type: Boolean }) showApply!: boolean;
    @Prop({ default: true, type: Boolean }) showReject!: boolean;
    data: DialogData = {
        input: '',
    };
    loading = false;

    mounted() {
        this.focusInput();
    }

    focusInput() {
        const input = this.$el.querySelector('input');
        if (input) {
            input.focus();
        }
    }

    async apply() {
        if (this.input?.validate) {
            const validation = this.input.validate(this.data.input);
            if (validation && validation.message) {
                this.$snotify.warning(validation.message, { position: SnotifyPosition.rightTop });
                this.focusInput();
                return;
            }
        }
        if (this.action && typeof this.action === 'function') {
            this.loading = true;
            try {
                await this.action();
                this.loading = false;
                this.$parent.$emit('apply', { ...this.data });
                this.$emit('close');
            } catch (error) {
                this.loading = false;
            }
            return;
        }
        this.$parent.$emit('apply', { ...this.data });
        this.$emit('close');
    }
    reject() {
        this.$emit('reject');
        this.$emit('close');
    }
}
