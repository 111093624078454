import { PageRequest, PageResult } from '@/shared/models/pagination';
import ListPageApiAdapter from './ListPageApiAdapter';
import endpoints from '../endpoints';
import { BackgroundPreview, MedialListFilterParams, MediaListFilters } from '@/modules/media/models';
import axios from 'axios';

type MediaPageRequest = PageRequest<MedialListFilterParams>;

export interface UploadParams {
    file: File | Blob;
    name?: string;
    isTemp?: boolean;
    familyId?: number;
    customNames?: string;
}

export interface ChangeBackgroundParams {
    backgroundId?: number;
    familyId?: number;
    customName?: string;
}

export class MediaApiAdapter implements ListPageApiAdapter<MedialListFilterParams, BackgroundPreview> {
    public constructor(private _endpoint: typeof endpoints.media) {}

    public async getPage(request: MediaPageRequest): Promise<PageResult<BackgroundPreview>> {
        const res = await axios.post<PageResult<BackgroundPreview>>(this._endpoint.getPage, request);
        return Promise.resolve(res.data);
    }

    public async getFilters() {
        return (await axios.get<MediaListFilters>(this._endpoint.getFilterParams)).data;
    }

    public async changeBackground({ customName, familyId, backgroundId }: ChangeBackgroundParams) {
        const params = { familyId, id: backgroundId, customName };
        return await axios
            .patch<{ backgrounds: BackgroundPreview[] }>(this._endpoint.base, null, {params})
            .then(res => res.data);
    }

    public async upload({ file, customNames, name, familyId, isTemp = false }: UploadParams) {
        const data = new FormData();
        data.append('file', file, name);
        const params = isTemp ? { isTemp, familyId, customNames: name } : { isTemp, familyId, customNames };
        return await axios
            .put<{ backgrounds: BackgroundPreview[] }>(this._endpoint.base, data, { params })
            .then(res => res.data);
    }

    public async delete(ids: number[]) {
        await axios.delete(this._endpoint.base, { params: { id: ids } });
    }
}

export default new MediaApiAdapter(endpoints.media);
