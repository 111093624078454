import { AuthTokenProvider } from './AuthTokenProvider';

export class AuthLSTokenProvider implements AuthTokenProvider {
    accessTokenKey = 'AccessToken';
    refreshTokenKey = 'RefreshToken';
    getToken(): (string | null) | Promise<string | null> {
        return localStorage.getItem(this.accessTokenKey);
    }
    setToken(token: string): void | Promise<void> {
        return localStorage.setItem(this.accessTokenKey, token);
    }
    getRefreshToken(): (string | null) | Promise<string | null> {
        return localStorage.getItem(this.refreshTokenKey);
    }
    setRefreshToken(token: string): void | Promise<void> {
        return localStorage.setItem(this.refreshTokenKey, token);
    }
    clear() {
        localStorage.removeItem(this.accessTokenKey);
        localStorage.removeItem(this.refreshTokenKey);
    }
}

export default new AuthLSTokenProvider();
