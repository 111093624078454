


































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalContainer from './ModalContainer.vue';
import MediaCard from '@/modules/media/components/MediaCard.vue';
import { BackgroundPreview } from '@modules/media/models';
import { PAGINATION_ACTIONS } from '@store/modules/paginator';
import { StateChanger } from 'vue-infinite-loading';
import { debounce } from '@/shared/utils/debounce';
import SearchPanel from './components/MediaSearchPanel.vue';
import { BackgroundListFilterParams, Sorting } from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

@Component({
    components: {
        ModalContainer,
        MediaCard,
        SearchPanel,
    },
})
export default class MediaModal extends Vue {
    @Prop({ required: true }) namespace!: string;
    @Prop({ type: Array }) families!: FamiliesType[];
    @Prop({ default: false, type: Boolean }) showSearchPanel!: boolean;
    searchParams: BackgroundListFilterParams = {
        query: '',
        sort: Sorting.ByName,
        sortAsc: false,
        dimensions: [],
    };

    mounted() {
        // this.$store.dispatch(`${this.namespace}/${PAGINATION_ACTIONS.load}`);
    }

    loadPage = debounce(async ({ event, reload = false }: { event?: StateChanger; reload: boolean }) => {
        await this.$store.dispatch(`${this.namespace}/${PAGINATION_ACTIONS.load}`, { reload, scrollEvent: event });
    }, 500);

    onChange(updatedSearchParams: BackgroundListFilterParams) {
        this.searchParams = updatedSearchParams;
        this.$store.commit(`${this.namespace}/setParams`, updatedSearchParams);
        this.loadPage({ reload: true });
    }

    use() {
        const selected = this.items.filter(i => this.selected.includes(i.id));
        this.$parent.$emit('use', selected && selected[0]);
        this.$store.commit(`${this.namespace}/select`, []);
        this.$emit('close');
    }

    get dataId() {
        return this.$store.getters[`${this.namespace}/dataId`] || 0;
    }

    get selected(): number[] {
        return this.$store.getters[`${this.namespace}/selected`] || [];
    }

    set selected(value: number[]) {
        this.$store.commit(`${this.namespace}/select`, value);
    }

    get items(): BackgroundPreview[] {
        return this.$store.getters[`${this.namespace}/pageItems`] || [];
    }

    get familyList() {
        return this.families.map(family => {
            return { value: String(family.id), label: family.name };
        });
    }
}
