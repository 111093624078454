import Vue from 'vue';
import { toPascalCase } from '../utils/toPascal';
/* eslint-disable @typescript-eslint/no-var-requires */
const requireComponent = require.context('.', true, /.*\.(ts)/);

requireComponent.keys().forEach(filePath => {
    // Получение конфигурации компонента
    const filterConfig = requireComponent(filePath);
    const fileName = (filePath.split('/').pop() as string).replace(/\.\w+$/, '');
    // Получение имени компонента в PascalCase
    const filterName = toPascalCase(fileName, true);
    Vue.filter(filterName, filterConfig.default || filterConfig);
});
