import Vue from 'vue';
import VTooltip from 'v-tooltip';
import VModal from 'vue-js-modal';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import vSelect from 'vue-select';
import router from './router';
import InfiniteLoading from 'vue-infinite-loading';
import VueSlider from 'vue-slider-component';

// plugin antd import must be above styles so antd styles don't overlap base styles
import './plugins/antd';

// styles
import 'vue-snotify/styles/material.css';
import 'vue-select/dist/vue-select.css';
import './assets/stylesheets/base-style.min.css';
import 'vue-slider-component/theme/antd.css';
import './common.scss';

// register global components
import '@components/register';
// register global filters
import '@shared/filters';
// register global directives
import '@shared/directives';

import App from './App.vue';
import { AxiosInterceptorsConfig, AxiosErrorNotification } from '@api';
import Authentication, { authService as auth } from './services/auth';
import SnotifyService from '@services/Notifier';
import { authGuard } from './router/guards/auth';
import store from './store';
import DialogService from './services/DialogService';
import { AUTH_EVENTS } from './services/auth/AuthService';

Vue.config.productionTip = false;
const errorNotification = new AxiosErrorNotification(SnotifyService);
const interceptorConfig = new AxiosInterceptorsConfig(errorNotification);

/* Init plugins */
Vue.use(InfiniteLoading, { slots: { noMore: '', noResults: 'No results' } });
Vue.use(Authentication);
Vue.use(VModal, {
    dynamic: true,
    dynamicDefaults: { clickToClose: false, height: 'auto', width: 600 },
    injectModalsContainer: true,
});
Vue.use(Snotify, { toast: { position: SnotifyPosition.rightTop } });
Vue.use(VTooltip, {
    defaultBoundariesElement: document.body,
    popover: {
        defaultBoundariesElement: document.body,
        defaultPopperOptions: {
            modifiers: {
                preventOverflow: {
                    enabled: false,
                    boundariesElement: document.body,
                },
            },
        },
    },
});

/* Global components */
Vue.component('v-select', vSelect);
Vue.component('vue-slider', VueSlider);

// configure interceptors
interceptorConfig.setupInterceptors(router);
interceptorConfig.setupAuthInterceptors(router, auth);

const root = new Vue({
    router,
    store,
    auth,
    render: h => h(App, { ref: 'app' }),
    mounted() {
        // pass snotify instance to service
        SnotifyService.init(this.$snotify);
        DialogService.init(this);

        auth.events.$on(AUTH_EVENTS.logout, () => {
            const modulesToClear = ['templates', 'banners', 'editor', 'media'];
            for (const storeKey of Object.keys(store.state)) {
                if (modulesToClear.includes(storeKey)) {
                    store.dispatch(`${storeKey}/reset`);
                }
            }
        });
    },
});

async function checkAuthorization() {
    return new Promise(async res => {
        await auth.ready;
        console.log(auth.claims);
        let valid = await auth.isAccessTokenValid();
        if (!valid) {
            try {
                const refreshed = await auth.refresh();
                if (refreshed) {
                    valid = true;
                }
            } catch (error) {
                errorNotification.displayError(error);
            }
        }
        valid && store.dispatch('getFamilies');
        router.beforeEach(authGuard(auth));
        router.onReady(() => {
            const route = router.currentRoute;
            const isLoginRoute = route?.name === 'login';
            if (!route.meta.requireAuth) {
                res(true);
            } else {
                if (!valid) {
                    !isLoginRoute && router.push('/login');
                } else {
                    const redirect = auth.claims?.Admin ? '/admin' : '/';
                    isLoginRoute && router.push(redirect);
                }
            }
            res(true);
        });
    });
}

checkAuthorization().then(() => root.$mount('#app'));
