




import { valueOrAuto } from '@/shared/utils/style';
import { Component } from 'vue-property-decorator';
import { ButtonWidget } from '../types/Widget';
import BaseContainer from './BaseContainer.vue';
import ButtonTemplate from './ButtonTemplate.vue';
@Component({
    components: { ButtonTemplate },
})
export default class Button extends BaseContainer<ButtonWidget> {
    get styles() {
        return {
            button: {
                ...this.getBaseWidgetStyles(),
                color: this.model.color,
                fontSize: `${this.schema.fontSize}px`,
                fontWeight: this.schema.fontWeight,
                lineHeight: this.schema.lineHeight,
                letterSpacing: this.schema.letterSpacing,
                whiteSpace: this.schema.whiteSpace,
                border: this.schema.border,
                borderRadius: this.schema.borderRadius,
                backgroundColor: this.schema.backgroundColor,
            },
            icon: {
                top: valueOrAuto(this.schema.icon?.top),
                left: valueOrAuto(this.schema.icon?.left),
                width: valueOrAuto(this.schema.icon?.width),
                height: valueOrAuto(this.schema.icon?.height),
                marginLeft: this.schema.icon?.marginLeft && `${this.schema.icon.marginLeft}px`,
                fill: this.schema.icon?.fill,
            },
        };
    }
    get icon() {
        return { styles: this.styles.icon, value: this.$options.filters?.assetsUrl(this.schema.icon?.defaultValue, this.assetsRoot) };
    }
}
