import Vue from 'vue';
import { toPascalCase } from '../utils/toPascal';
/* eslint-disable @typescript-eslint/no-var-requires */
const requireComponent = require.context('.', true, /.*\.(vue)/);

requireComponent.keys().forEach(filePath => {
    // Получение конфигурации компонента
    const componentConfig = requireComponent(filePath);
    const fileName = (filePath.split('/').pop() as string).replace(/\.\w+$/, '');
    // Получение имени компонента в PascalCase
    const componentName = toPascalCase(fileName);
    Vue.component(componentName, componentConfig.default || componentConfig);
});
