import Vuex, { Store } from 'vuex';
import Vue from 'vue';
import familyAdapter from '@services/api/adapters/FamilyAdapter';
import { PageResult, Sorting } from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

Vue.mixin({
    methods: {
        $registerVuexModule(name: string | string[], module: any) {
            const modules = Array.isArray(name) ? [...name] : [name];
            const parentModules = modules.splice(0, modules.length - 1);
            const targetModule = modules[0];
            const parent = parentModules.length
                ? parentModules.reduce((acc, cur) => (typeof acc !== 'string' ? acc[cur] || cur : cur), this.$store.state)
                : this.$store.state;
            if (typeof parent === 'string') {
                console.error(
                    `Cannot register vuex module ${targetModule} with path: ${parentModules.join(
                        '.'
                    )}.${targetModule} , module: ${parent} doesnt exist`
                );
                return;
            }
            if (!parent[targetModule]) {
                this.$store.registerModule(name as string, module);
            }
        },
    },
});

Vue.use(Vuex);

const store = new Store<RootState>({
    state: {
        menuCollapsed: false,
        families: [],
    },
    mutations: {
        collapseMenu(state, collapse: boolean) {
            state.menuCollapsed = collapse;
        },
        setFamilies(state, families: PageResult<FamiliesType>) {
            state.families = families.rows;
        },
    },
    modules: {},
    actions: {
        async getFamilies({ commit }) {
            commit(
                'setFamilies',
                await familyAdapter.getPage({ size: 100, params: { query: '', sort: Sorting.ByName, sortAsc: true, options: 2 } })
            );
        },
    },
});
export default store;
