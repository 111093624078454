import endpoints from '../../endpoints';
import { TemplatesApiAdapter } from '@/services/api/adapters/TemplatesApiAdapter';
import axios from 'axios';
import { Template } from '@shared/models/Template';
import { parseJsonFields } from '@/shared/utils';
import { ListFilterParams, PageResult, Sorting } from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

type ManageFamiliesPageRequest = Partial<ListFilterParams>;

class TemplatesAdminApiAdapter extends TemplatesApiAdapter {
    public constructor(private _adminEndpoints: typeof endpoints.templatesAdmin) {
        super(endpoints.templatesAdmin);
    }

    public async cloneTemplate(id: number) {
        const page = await axios.get<{ templateClone: Template }>(this._adminEndpoints.cloneTemplate, {
            params: { id },
        });
        return parseJsonFields<Template>(page.data.templateClone, { content: 'contentSchema' });
    }

    public async deleteTemplate(ids: number[]) {
        const page = await axios.delete<void>(this._adminEndpoints.base, {
            data: { ids },
        });
        return page.data;
    }

    public async getFamilies(request: ManageFamiliesPageRequest) {
        const defaultParams = {
            query: '',
            sortAsc: false,
            options: 1,
            sort: Sorting.ByDate,
            dimensions: [],
            familyIds: [],
        };
        const currentParams = Object.assign(defaultParams, request);
        const data = {
            size: 50,
            index: 0,
            params: currentParams,
        };
        const page = await axios.post<PageResult<FamiliesType[]>>('/api/family/admin/page', data);
        return page.data.rows;
    }
}

export default new TemplatesAdminApiAdapter(endpoints.templatesAdmin);
