export function getLastModified(): number[] {
    try {
        return JSON.parse(localStorage.getItem('lastModified') || '[]');
    } catch (error) {
        return [];
    }
}

export function getLastModifiedFamily() {
    const family = localStorage.getItem('lastModifiedFamily');
    return family && parseInt(family);
}

export function setLastModified(ids: number[], templateFamily?: number) {
    localStorage.setItem('lastModified', JSON.stringify(ids));
    templateFamily && localStorage.setItem('lastModifiedFamily', String(templateFamily));
}
