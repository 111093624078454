import { parseISO, format } from 'date-fns';

export function safeParseDate(date: string | Date, fallback?: any) {
    let res: Date;
    if (date instanceof Date) {
        res = date;
    } else {
        try {
            res = parseISO(date as string);
        } catch (error) {
            res = new Date(date);
        }
    }
    return !isNaN(res.getTime()) ? res : fallback;
}

export function safeFormatDate(date: string | Date, formatPattern: string) {
    const parsedDate = safeParseDate(date);
    return parsedDate instanceof Date || typeof date === 'number' ? format(parsedDate, formatPattern) : '';
}
