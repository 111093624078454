import { render, staticRenderFns } from "./ModalContainer.vue?vue&type=template&id=b70a45d8&scoped=true&"
import script from "./ModalContainer.vue?vue&type=script&lang=ts&"
export * from "./ModalContainer.vue?vue&type=script&lang=ts&"
import style0 from "@styles/components/popup.css?vue&type=style&index=0&id=b70a45d8&scoped=true&lang=css&"
import style1 from "@styles/components/button-icon.css?vue&type=style&index=1&id=b70a45d8&scoped=true&lang=css&"
import style2 from "./ModalContainer.vue?vue&type=style&index=2&id=b70a45d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b70a45d8",
  null
  
)

export default component.exports