






import { Component } from 'vue-property-decorator';
import BaseTemplate from './BaseTemplate.vue';
@Component
export default class TemplateIcon extends BaseTemplate {}
