import { AuthTokenProvider } from './AuthTokenProvider';
import decodeJwt from 'jwt-decode';
import Vue from 'vue';
import { AuthApiProvider } from './AuthApiProvider';

export enum AUTH_EVENTS {
    needRefresh = 'need_refresh',
    authSuccess = 'auth_success',
    authRejected = 'auth_reject',
    logout = 'logout',
}

interface AuthClaims {
    DisplayName: string;
    AccountName: string;
    Designer: boolean;
    Admin: boolean;
    UserId: string;
    nbf: number;
    exp: number;
    iat: number;
}

const storedAtTokenKey = 'AccessTokenStoredAt';

export class AuthService {
    claims?: AuthClaims;
    timer?: number;
    timeoutFactor = 0.75;
    events = new Vue();
    ready!: Promise<void>;

    constructor(private tokenProvider: AuthTokenProvider, private apiProvider?: AuthApiProvider) {
        this.ready = this.init();
    }

    private async init() {
        if (await this.isAccessTokenValid()) {
            this.setupRefreshTimer();
            await this.setClaims();
        }
    }

    get storedAt() {
        const res = localStorage.getItem(storedAtTokenKey);
        return res ? parseInt(res, 10) : null;
    }

    set storedAt(value: number | null) {
        if (value) {
            localStorage.setItem(storedAtTokenKey, String(value));
        } else {
            localStorage.removeItem(storedAtTokenKey);
        }
    }

    async getToken() {
        return await this.tokenProvider.getToken();
    }

    async setToken(token: string, refreshToken?: string) {
        this.storedAt = Date.now();
        await this.setClaims(token);
        await this.tokenProvider.setToken(token);
        if (refreshToken) {
            await this.setRefreshToken(refreshToken);
        }
        this.setupRefreshTimer();
    }

    async getRefreshToken() {
        return await this.tokenProvider.getRefreshToken();
    }

    async setRefreshToken(token: string) {
        return await this.tokenProvider.setRefreshToken(token);
    }

    async login(user: string, password: string) {
        if (!this.apiProvider) {
            return false;
        }
        try {
            const res = await this.apiProvider.getToken(user, password);
            if ('data' in res) {
                await this.setToken(res.data.accessToken, res.data.refreshToken);
            }
            return true;
        } catch (error) {
            return false;
        }
    }

    async refresh() {
        if (!this.apiProvider || !(await this.isRefreshTokenValid())) {
            this.clear();
            return false;
        }
        const refreshToken = await this.getRefreshToken();
        if (refreshToken) {
            const res = await this.apiProvider.refreshToken(refreshToken);
            if ('data' in res) {
                await this.setToken(res.data.accessToken, res.data.refreshToken);
                return true;
            }
        }
    }

    async logOut(isAnonymous = false) {
        if (!this.apiProvider) {
            return false;
        }
        try {
            if (!isAnonymous) await this.apiProvider.deleteToken();
            this.clear();
            this.events.$emit(AUTH_EVENTS.logout);
            return true;
        } catch (error) {
            return false;
        }
    }

    private async clear() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
        await this.tokenProvider.clear();
        this.storedAt = null;
        this.setClaims(null);
    }

    public async isAccessTokenValid() {
        const token = await this.getToken();
        return token ? this.isTokenExipired(token) : false;
    }

    public async isRefreshTokenValid() {
        const token = await this.getRefreshToken();
        return token ? this.isTokenExipired(token) : false;
    }

    private async setClaims(token?: string | null) {
        token = token || (await this.getToken());
        if (token) {
            this.claims = decodeJwt(token);
        }
    }

    private isTokenExipired(token: string) {
        const claims: { exp: number } = decodeJwt(token);
        return Date.now() - claims.exp * 1000 < 0;
    }

    private async setupRefreshTimer() {
        const token = await this.getToken();
        if (this.storedAt && token) {
            const claims: { exp: number } = decodeJwt(token);
            const timeout = this.calcTimeout(claims.exp * 1000, this.storedAt);
            this.timer = setTimeout(() => {
                this.refresh();
                this.events.$emit(AUTH_EVENTS.needRefresh);
            }, timeout);
        }
    }

    private calcTimeout(storedAt: number, expiration: number): number {
        const now = Date.now();
        const delta = (expiration - storedAt) * this.timeoutFactor - (now - storedAt);
        return Math.max(0, delta);
    }
}
