
import { Component } from 'vue-property-decorator';
import { CreateElement } from 'vue';
import EditorFiledMixin from './EditorFiledMixin.vue';

@Component
export default class EditorTextField extends EditorFiledMixin {
    onColorChange($event: string) {
        this.$emit('change', { color: $event, label: this.schema.label });
    }
    get value() {
        return this.model.value;
    }
    onInputValueChange(event: Event) {
        this.$emit('change', { value: (event.target as HTMLInputElement).value });
    }

    onVisibilityChange(event: Event) {
        this.$emit('change', { hidden: (event.target as HTMLInputElement).checked });
    }

    get isHidden() {
        return this.model.hidden;
    }

    render(h: CreateElement) {
        if (!this.schema) return null;
        return h(
            'FormControl',
            {
                props: { label: this.schema.label },
            },
            [
                h('FormCheckbox', { props: { title: 'Hide', reverse: true }, slot: 'checkbox' }, [
                    h('input', {
                        attrs: { class: 'form-checkbox__field', type: 'checkbox', name: 'checkbox' },
                        domProps: { checked: this.isHidden },
                        on: { input: this.onVisibilityChange },
                    }),
                ]),
                h(this.schema.type, {
                    staticClass: this.schema.type === 'textarea' ? 'form-control__field-textarea' : '',
                    domProps: { value: this.model.value },
                    on: {
                        input: this.onInputValueChange,
                    },
                    directives: this.schema.type === 'textarea' ? [{ name: 'autoResize' }] : [],
                }),
                h('ColorPallete', {
                    props: { value: this.model.color, label: this.schema.label, palette: this.palette },
                    on: { input: this.onColorChange },
                }),
            ]
        );
    }
}
