import Vue from 'vue';
import DialogModal from '@shared/components/Modal/DialogModal.vue';
class DialogService {
    private _vm!: Vue;
    init(vm: Vue) {
        this._vm = vm;
    }

    showEditorDialog(): Promise<void> {
        return new Promise(async (res, rej) => {
            /* Additionally check if user logged in, need to prevent duplicate dialog on logout action */
            if (this._vm.$store.getters['editor/hasUnsaved'] && (await this._vm.$auth.isAccessTokenValid())) {
                this._vm.$modal.show(
                    DialogModal,
                    {
                        title: 'Unsaved changes',
                        question: 'You have unsaved changes that will be lost. Do you realy want leave page?',
                        applyText: 'leave',
                        rejectText: 'cancel',
                    },
                    {
                        clickToClose: false,
                    },
                    {
                        apply: res,
                        reject: rej,
                    }
                );
            } else {
                res();
            }
        });
    }
}

export default new DialogService();
