import { Template, TemplateModel } from '../Template';
import { uuid } from '../../utils/uuid';
import { Image } from '../Image';
import { Background } from '@/modules/media/models';
import { TemplateSchema } from '@/packages/template-renderer/types/Template';
import { FamiliesType } from '@shared/models/FamiliesType';

export interface Banner {
    clientSideBannerId: string;
    id: number;
    name: string;
    template: Template;
    modelJson: string;
    backgroundImage?: string;
    background?: Background;
    backgroundId?: number;
    generatedImageId?: string;
    model: TemplateModel;
}

export interface BannerSave {
    bannerId: number;
    clientSideBannerId?: string;
    name: string;
    templateId: number;
    modelJson: string;
    backgroundId?: number;
    generatedImageId?: string;
}

export interface BannerSaved {
    bannerId: number;
    clientSideBannerId: string;
    name: string;
    generatedImage: Image;
}

export function templateToBanner(template: Template): Banner {
    const parsedModel = JSON.parse(template.modelJson);
    return {
        model: {
            backgroundPosition: parsedModel.backgroundPosition,
            backgroundSize: parsedModel.backgroundSize,
            fields: [],
        },
        template,
        modelJson: '',
        backgroundImage: '',
        name: template.name,
        id: 0,
        clientSideBannerId: uuid(),
        backgroundId: template.background.id,
    };
}
