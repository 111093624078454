import { toPascalCase } from '@/shared/utils/toPascal';
import { EsModuleComponent, Component } from 'vue/types/options';

export function requireVueComponents(r: __WebpackModuleApi.RequireContext) {
    const components: Record<string, Component> = {};
    r.keys().forEach(filePath => {
        const component = r(filePath) as EsModuleComponent;
        const fileName = (filePath.split('/').pop() as string).replace(/\.\w+$/, '');
        const componentName = toPascalCase(fileName);
        components[componentName] = component.default;
    });
    return components;
}
export default requireVueComponents;
