





















import { Vue, Component, Prop } from 'vue-property-decorator';
import { PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { State } from 'vuex-class';
import CropperModal from '@/shared/components/Modal/CropperModal.vue';
import MediaModal from '@components/Modal/MediaModal.vue';
import { BackgroundPreview } from '../../media/models';
import { getFileByUrl } from '@shared/utils/getFileByUrl';
import { FamiliesType } from '@shared/models/FamiliesType';
const uploaderNamespace = 'editor/uploader';
@Component
export default class EditBackground extends Vue {
    @Prop({ default: '', type: String }) src!: string;
    @Prop({ default: [], type: Array }) families!: FamiliesType[];
    @State('selected', { namespace: 'editor' }) selected!: number[];

    buttons = [
        // { action: 'file-upload', title: 'Upload image' },
        { action: 'images', title: 'Change background image' },
        { action: 'crop', title: 'Crop background image' },
    ];

    get cropperHistory(): BackgroundPreview[] {
        const ids = this.$store.state.editor.cropperHistory[this.selected[0]];
        return this.$store.getters['editor/getBackgrouds'](ids);
    }

    async onButtonClick(action: 'file-upload' | 'crop' | 'images') {
        switch (action) {
            case 'file-upload':
                this.$store.commit(`${uploaderNamespace}/reset`);
                this.showCropper();
                break;
            case 'crop':
                if (this.src) {
                    await this.setUploderFile(this.src);
                    this.showCropper();
                }
                break;
            case 'images':
                this.showGalery();
            default:
                break;
        }
    }
    showCropper() {
        this.$modal.show(
            CropperModal,
            {
                namespace: 'editor/uploader',
                uploadAction: 'editor/upload',
                resetAfterUpload: false,
                uploader: false,
                title: 'Crop',
                cropperHistory: [...this.cropperHistory],
            },
            {},
            {
                saved: async () => {
                    this.$store.commit(`editor/galery/${PAGINATION_MUTATIONS.resetData}`);
                },
                undo: async (bg: BackgroundPreview) => {
                    this.$store.commit('editor/undoBgSnapshot', bg.id);
                    this.$store.commit('editor/updateBg', bg);
                },
            }
        );
    }

    async setUploderFile(url: string) {
        this.$store.commit(`${uploaderNamespace}/setFile`, await getFileByUrl(url));
        this.$store.commit(`${uploaderNamespace}/go`, 2);
    }

    showGalery() {
        this.$modal.show(
            MediaModal,
            { namespace: 'editor/galery', families: this.families, showSearchPanel: true },
            {},
            {
                use: async (image: BackgroundPreview) => {
                    if (image) {
                        this.$emit('change', image);
                    }
                },
            }
        );
    }
}
