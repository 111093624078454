import { SnotifyService } from 'vue-snotify/SnotifyService';
import { SnotifyEvent, SnotifyToast, SnotifyPosition, Snotify } from 'vue-snotify';
import { AsyncNotification } from './AsyncNotification';

interface DefferedErrorConfig {
    html: string;
    duration: number;
    events: Map<SnotifyEvent, (toast: SnotifyToast) => void>;
}

export class NotifierService {
    private static duration = 5000;
    private defferedErrors: DefferedErrorConfig[] = [];
    public $snotify!: SnotifyService;

    public showSuccess(
        text: string,
        title = 'Success',
        duration: number = NotifierService.duration,
        events: Map<SnotifyEvent, (toast: SnotifyToast) => void> = new Map<SnotifyEvent, (toast: SnotifyToast) => void>()
    ): void {
        if (this.$snotify) {
            const toast = this.$snotify.success(text, title, {
                timeout: duration,
                closeOnClick: true,
                position: SnotifyPosition.rightTop,
            });
            this.setupToastEvents(toast, events);
        }
    }

    public showInfo(
        text: string,
        title = 'Info',
        duration: number = NotifierService.duration,
        events: Map<SnotifyEvent, (toast: SnotifyToast) => void> = new Map<SnotifyEvent, (toast: SnotifyToast) => void>()
    ): void {
        if (this.$snotify) {
            const toast = this.$snotify.info(text, title, {
                timeout: duration,
                closeOnClick: true,
                position: SnotifyPosition.rightTop,
            });
            this.setupToastEvents(toast, events);
        }
    }

    public showWarning(
        text: string,
        title = 'Warning',
        duration: number = NotifierService.duration,
        events: Map<SnotifyEvent, (toast: SnotifyToast) => void> = new Map<SnotifyEvent, (toast: SnotifyToast) => void>()
    ): void {
        if (this.$snotify) {
            const toast = this.$snotify.warning(text, title, {
                timeout: duration,
                closeOnClick: true,
                position: SnotifyPosition.rightTop,
            });
            this.setupToastEvents(toast, events);
        }
    }

    public showError(
        text: string,
        title = 'Error',
        duration: number = NotifierService.duration,
        events: Map<SnotifyEvent, (toast: SnotifyToast) => void> = new Map<SnotifyEvent, (toast: SnotifyToast) => void>()
    ): void {
        if (this.$snotify) {
            const toast = this.$snotify.error(text, title, {
                html: text,
                timeout: duration,
                closeOnClick: false,
                position: SnotifyPosition.rightTop,
            });
            this.setupToastEvents(toast, events);
        } else {
            // this.defferedErrors.push({ html: text, duration, events });
        }
    }

    public showServerError(
        html: string,
        duration: number = NotifierService.duration,
        events: Map<SnotifyEvent, (toast: SnotifyToast) => void> = new Map<SnotifyEvent, (toast: SnotifyToast) => void>()
    ): void {
        if (this.$snotify) {
            const toast = this.$snotify.html(html, {
                timeout: duration,
                closeOnClick: false,
                position: SnotifyPosition.rightTop,
            });
            this.setupToastEvents(toast, events);
        } else {
            // this.defferedErrors.push({ html, duration, events });
        }
    }

    public showAsync(
        notification: AsyncNotification,
        duration: number = NotifierService.duration,
        events: Map<SnotifyEvent, (toast: SnotifyToast) => void> = new Map<SnotifyEvent, (toast: SnotifyToast) => void>()
    ): void {
        if (this.$snotify) {
            const toast = this.$snotify.async(
                notification.text,
                notification.title,
                () =>
                    new Promise<Snotify>((resolve, reject) => {
                        notification.promise
                            .then((result: any) => {
                                resolve({
                                    title: notification.successTitle ? notification.successTitle : notification.title,
                                    body: notification.successText ? notification.successText : 'Success',
                                    config: {
                                        closeOnClick: true,
                                        position: SnotifyPosition.rightTop,
                                        timeout: duration,
                                    },
                                });
                            })
                            .catch((error: any) => {
                                reject({
                                    title: notification.failTitle ? notification.failTitle : notification.title,
                                    body: notification.failText ? notification.failText : 'Success',
                                    config: {
                                        closeOnClick: true,
                                        position: SnotifyPosition.rightTop,
                                        timeout: duration,
                                    },
                                });
                            });
                    }),
                {
                    position: SnotifyPosition.rightTop,
                }
            );
            this.setupToastEvents(toast, events);
        }
    }

    public init(snotify: SnotifyService): void {
        this.$snotify = snotify;
        if (this.defferedErrors) {
            this.defferedErrors.forEach(({ html, events, duration }) => this.showError(html, 'Attention', duration, events));
        }
    }

    private setupToastEvents(
        toast: SnotifyToast,
        events: Map<SnotifyEvent, (toast: SnotifyToast) => void> = new Map<SnotifyEvent, (toast: SnotifyToast) => void>()
    ): void {
        if (events.size) {
            events.forEach((handler: (toast: SnotifyToast) => void, name: SnotifyEvent) => {
                toast.on(name, handler);
            });
        }
    }
}

export default new NotifierService();
