export function debounce<F extends (...args: any[]) => any>(func: F, wait: number, immediate = false) {
    let timeout: number | undefined;
    return function(this: any, ...args: Parameters<F>) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        const later = function() {
            timeout = undefined;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply<any, Parameters<F>, ReturnType<F>>(context, args);
    };
}
