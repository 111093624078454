export function downloadFromLink(url: string, name?: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = name || '';
    link.style.cssText = 'position: absolute; left: 1000, top: 0';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function saveImage(file: Blob, name?: string): void {
    const fileName = file instanceof File ? file.name : name;
    const objectUrl = URL.createObjectURL(file);
    downloadFromLink(objectUrl, fileName);
    URL.revokeObjectURL(objectUrl);
}
