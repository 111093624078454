import { Banner } from '../models/banners/Banner';
import { TemplateModelField } from '../models/Template';

/* pre-populate all values with defaults */
export function prepareBannerDataModel(banner: Banner, escape = false) {
    const bannerFields: TemplateModelField[] = [];
    banner.template.contentSchema.widgets.forEach(widget => {
        const model = banner.model.fields?.find(field => field.label === widget.label);
        let value: string = model?.value || widget.defaultValue || '';
        if (!value && widget.type === 'icon') {
            value = widget.label
                .split(/(?:-|\s)/)
                .slice(0, 2)
                .map(s => s.toUpperCase()[0])
                .join('');
        }
        bannerFields.push(model ? { ...model, value } : { label: widget.label, value, hidden: false });
    });
    return {
        ...banner.model,
        fields: bannerFields,
        backgroundImage: banner.backgroundImage || banner.template?.background?.image?.defaultUrl,
        width: banner.template.width,
        height: banner.template.height,
    };
}

export function getBannerId(banner: Banner) {
    return banner.id || banner.clientSideBannerId;
}
