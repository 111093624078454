import { PageRequest, PageResult } from '@/shared/models/pagination';
import ListPageApiAdapter from './ListPageApiAdapter';
import endpoints from '../endpoints';
import axios from 'axios';
import { BannerListFilterParams, BannerPreview, BannerListFilters } from '@/modules/banners/models';
import { Banner, BannerSave } from '@/shared/models/banners/Banner';
import { parseJsonFields } from '@/shared/utils/parseJsonFileds';
import { BannerResources } from '@/shared/models/banners/BannerResources';
import { BannersSavedResponse } from '@/shared/models';
import { Template } from '@/shared/models/Template';
import { unescape, uuid } from '@/shared/utils';

type BannerPageRequest = PageRequest<BannerListFilterParams>;

export class BannerApiAdapter implements ListPageApiAdapter<BannerListFilterParams, BannerPreview> {
    public constructor(private _endpoints: typeof endpoints) {}

    public async getPage(request: BannerPageRequest) {
        return (await axios.post<PageResult<BannerPreview>>(this._endpoints.banners.getPage, request)).data;
    }

    public async getBannersWithScheme(ids: number[]) {
        const { data } = await axios.get<{ banners: Banner[] }>(this._endpoints.banners.base, { params: { id: ids } });
        return data.banners.map(b => {
            parseJsonFields<Banner>(b);
            parseJsonFields<Template>(b.template, { content: 'contentSchema' });
            for (const key of Object.keys(b.model)) {
                const value = b.model[key];
                if (typeof value === 'string') {
                    b.model[key] = unescape(value);
                }
            }
            if (!b.model.color) {
                b.model.color = {};
            }
            b.clientSideBannerId = uuid();
            b.backgroundImage = b.background?.image.defaultUrl;
            b.backgroundId = b.background?.id;
            return b;
        });
    }

    public async getBannerEditingResources() {
        const { data } = await axios.get<BannerResources>(this._endpoints.banners.getEditingParams);
        return data;
    }

    public async getBannerFilters() {
        const { data } = await axios.get<BannerListFilters>(this._endpoints.banners.getFilterParams);
        return data;
    }
    public async generate(model: BannerSave) {
        return await axios.post(this._endpoints.banners.generate, model, { responseType: 'blob' });
    }
    public async save(banners: BannerSave[]) {
        return await axios.post<BannersSavedResponse>(this._endpoints.banners.base, { banners });
    }
    public async delete(ids: number[]) {
        return await axios.delete<void>(this._endpoints.banners.base, { params: { id: ids } });
    }

    public async logBannerDownload(bannerId: number) {
        return await axios.post(this._endpoints.banners.logDownload, { id: bannerId });
    }
}

export default new BannerApiAdapter(endpoints);
