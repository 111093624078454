
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TemplateField } from '../models/TemplateFields';

@Component
export default class EditorFiledMixin<M = { [key: string]: any }> extends Vue {
    @Prop() schema!: TemplateField;
    @Prop() model!: M;
    @Prop({ default: () => ['#ffffff', '#26bb16', '#3b4747'] }) palette!: string[];
}
