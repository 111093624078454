export function toPascalCase(str: string, camel = false) {
    str = str
        .replace(/\-/g, ' ') //convert all hyphens to spaces
        .replace(/\s[a-z]/g, it => it.toUpperCase()) //convert first char of each word to UPPERCASE
        .replace(/\s+/g, ''); //remove spaces
    if (camel) {
        str = str.replace(/^[A-Z]/g, it => it.toLowerCase()); //convert first char to lowercase
    }
    return str;
}
