





























import { Vue, Component, Model, Prop } from 'vue-property-decorator';

@Component
export default class SortFilter extends Vue {
    @Model('change', { type: Number }) readonly value!: number;
    @Prop({ type: Boolean }) sortAsc!: number;
    @Prop({ default: () => ({}), type: Object }) options!: Record<number, string>;
}
