




























import { Vue, Component, Model, Prop } from 'vue-property-decorator';

@Component
export default class Toolbar extends Vue {
    @Model('input', { type: String }) readonly value!: number;
    @Prop({ default: true }) search!: boolean;
}
