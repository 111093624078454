import { render, staticRenderFns } from "./EditBackground.vue?vue&type=template&id=551f36f4&scoped=true&"
import script from "./EditBackground.vue?vue&type=script&lang=ts&"
export * from "./EditBackground.vue?vue&type=script&lang=ts&"
import style0 from "@styles/components/edit-background.css?vue&type=style&index=0&id=551f36f4&scoped=true&lang=css&"
import style1 from "@styles/components/image-box.css?vue&type=style&index=1&id=551f36f4&scoped=true&lang=css&"
import style2 from "./EditBackground.vue?vue&type=style&index=2&id=551f36f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "551f36f4",
  null
  
)

export default component.exports