








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BackLink extends Vue {
    @Prop({ required: false }) to!: string;
    back() {
        this.to ? this.$router.push(this.to) : this.$router.back();
    }
}
