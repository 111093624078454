import Vue from 'vue';
import {
    Layout,
    Menu,
    Table,
    Input,
    AutoComplete,
    Button,
    PageHeader,
    Space,
    Modal,
    Upload,
    Icon,
    Select,
    InputNumber,
    Form,
    Row,
    Col,
    Tag,
    Divider,
    FormModel,
} from 'ant-design-vue';

Vue.use(Layout);
Vue.use(Menu);
Vue.use(Table);
Vue.use(Input);
Vue.use(AutoComplete);
Vue.use(Button);
Vue.use(PageHeader);
Vue.use(Space);
Vue.use(Modal);
Vue.use(Upload);
Vue.use(Icon);
Vue.use(Select);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tag);
Vue.use(Divider);

Vue.prototype.$confirm = Modal.confirm;
