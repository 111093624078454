/* eslint-disable prettier/prettier */
export function escape(s: string) {
    return s
        .replace(/[\\]/g, '\\\\')
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');
}
/* TODO */
export function unescape(s: string) {
    return s.replace(/\"/g, '"');
    // .replace(/[\"]/g, '"')
    // .replace(/[\/]/g, '/')
    // .replace(/[\b]/g, 'b')
    // .replace(/[\f]/g, 'f')
    // .replace(/[\n]/g, 'n')
    // .replace(/[\r]/g, '\\r')
    // .replace(/[\t]/g, '\\t');
}
