














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PageHeader extends Vue {
    @Prop({ default: 'Title', type: String }) title: string | undefined;
    @Prop({ default: '', type: String }) subtitle: string | undefined;
}
