





















































































import ModalContainer from './ModalContainer.vue';
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { getFileByUrl } from '../../utils';
import { Cropper } from './cropper';
import { SnotifyPosition } from 'vue-snotify';
import { BackgroundPreview } from '@modules/media/models';

interface CropBox {
    width: number;
    height: number;
}

@Component({ components: { ModalContainer, VueCropper } })
export default class CropperModal extends Vue {
    @Prop() uploadAction!: string;
    @Prop() bgFamilyId!: number;
    @Prop() title!: string;
    @Prop({ default: false }) uploader!: boolean;
    @Prop({ default: () => [] }) cropperHistory!: BackgroundPreview[];
    @Prop({ default: 'media/uploader', type: String, required: true }) namespace!: string;
    @Prop({ default: true, type: Boolean }) resetAfterUpload!: boolean;
    @Prop({ default: 300, type: Number }) width!: number;
    @Prop({ default: 300, type: Number }) height!: number;
    @Ref('uploadControl') readonly inputControl!: HTMLInputElement;
    @Ref('cropper') readonly cropper!: Cropper;
    activeBgHistory!: BackgroundPreview;
    dirty!: boolean;

    box: CropBox = { width: 200, height: 200 };
    dndObj = {
        isOver: false,
        count: 0,
    };
    loading = false;

    storePath(path: string) {
        return `${this.namespace}/${path}`;
    }

    get stage() {
        return this.$store.getters[this.storePath('activeStage')];
    }

    get file(): File {
        return this.$store.getters[this.storePath('file')];
    }

    get cropped(): Blob {
        return this.$store.getters[this.storePath('cropped')];
    }

    getImageScaleRatio() {
        const imageData = this.cropper.getImageData();
        return {
            height: imageData.naturalHeight / imageData.height,
            width: imageData.naturalWidth / imageData.width,
        };
    }

    onDrag = (e: DragEvent, inc: number) => {
        this.dndObj.count += inc;
        this.dndObj.isOver = this.dndObj.count === 0 ? false : true;
    };

    goToStage(stage: number) {
        if (this.stage === stage || (stage === 2 && !this.file)) {
            return;
        }
        this.$store.commit(this.storePath('go'), stage);
    }

    setFile(file: File | null) {
        this.$store.commit(this.storePath('setFile'), file);
    }

    reset() {
        this.$store.commit(this.storePath('reset'));
    }

    cancel() {
        this.reset();
        this.$emit('close');
    }

    simulateControlClick() {
        this.inputControl.click();
    }

    @Watch('stage')
    onStageChanged() {
        this.replace();
    }
    get imageSrc() {
        return this.file ? URL.createObjectURL(this.file) : null;
    }

    replace() {
        if (this.cropper && this.imageSrc) {
            this.cropper.replace(this.imageSrc, false);
            this.dirty = false;
        }
    }

    updateBox(box: { width: number; height: number }) {
        for (const key of Object.keys(box) as ('width' | 'height')[]) {
            box[key] = parseInt(`${box[key]}`);
        }
        this.box = { ...this.box, ...box };

        this.cropper.setCropBoxData({
            width: this.box.width / this.cropper.getImageData().aspectRatio,
            height: this.box.height / this.cropper.getImageData().aspectRatio,
        });
    }

    upload(event: InputEvent | DragEvent) {
        this.dndObj.count = 0;
        this.dndObj.isOver = false;
        let file!: File;
        if (event instanceof DragEvent && event.dataTransfer?.files.length) {
            file = event.dataTransfer.files[0];
        } else {
            const tg = event.target as HTMLInputElement;
            if (tg.files?.length) {
                file = tg.files[0];
            }
        }

        if (file && this.validateFileExtension(file)) {
            return this.setFile(file);
        } else {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    cropmove() {
        this.box = { ...this.getCropperBoxActualSize() };
        this.dirty = true;
    }

    ready() {
        this.box = { ...this.getCropperBoxActualSize() };
        this.dirty = false;
    }

    getCropperBoxActualSize() {
        const { width, height } = this.cropper.getCropBoxData();
        const r = this.getImageScaleRatio();

        return {
            width: Math.floor(width * Math.max(r.height, r.width)),
            height: Math.floor(height * Math.max(r.height, r.width)),
        };
    }

    async setCropped() {
        if (this.activeBgHistory && !this.dirty) {
            this.$parent.$emit('undo', this.activeBgHistory);
            this.$emit('close');
            return;
        } else {
            this.cropper.getCroppedCanvas().toBlob(async (blob) => {
                if (blob) {
                    this.$store.commit(`${this.namespace}/setCropped`, blob);
                }
                await this.saveAndClose();
            }, this.file.type);
        }
    }

    async saveAndClose() {
        if (this.uploadAction) {
            this.loading = true;
            try {
                await this.$store.dispatch(this.uploadAction, this.bgFamilyId);
                if (this.resetAfterUpload) {
                    this.reset();
                }
            } catch (error) {
            } finally {
                this.loading = false;
            }
            this.$parent.$emit('saved');
        }
        this.$emit('close');
    }

    async undo() {
        if (this.cropperHistory) {
            this.cropperHistory.pop();
            this.activeBgHistory = this.cropperHistory[this.cropperHistory.length - 1];
            const file = await getFileByUrl(this.activeBgHistory.defaultUrl);
            this.$store.commit(`${this.namespace}/setFile`, file);
            this.$nextTick(() => {
                this.replace();
            });
        }
    }

    private validateFileExtension(file: File) {
        const allowedExtensions = /^.+(.jpeg|.jpg|.png)$/;
        const valid = allowedExtensions.test(file.name);
        if (!valid) {
            this.$snotify.error('Only following image formats supported: jpg,jpeg,png', { position: SnotifyPosition.rightTop });
        }
        return valid;
    }
}
