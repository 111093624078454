import { RouteConfig } from 'vue-router';
const Templates = () => import(/* webpackChunkName: "admin-chunk" */ '../Templates/Templates.vue');
const Logs = () => import(/* webpackChunkName: "admin-chunk" */ '../Logs/Logs.vue');
const Backgrounds = () => import(/* webpackChunkName: "admin-chunk" */ '../Backgrounds/Backgrounds.vue');
const Icons = () => import(/* webpackChunkName: "admin-chunk" */ '../Icons/Icons.vue');
const Layout = () => import(/* webpackChunkName: "admin-chunk" */ '../Layout/Layout.vue');
const FamiliesManagement = () => import(/* webpackChunkName: "admin-chunk" */ '../FamiliesManagement/FamiliesManagement.vue');
const Editor = () => import(/* webpackChunkName: "admin-chunk" */ '../Editor/Editor.vue');

export const AdminRoutes: RouteConfig[] = [
    {
        path: '/admin',
        name: 'admin',
        component: Layout,
        meta: {
            requireAuth: true,
        },
        children: ([
            { path: '', redirect: 'admin-templates' },
            { path: 'templates', name: 'admin-templates', component: Templates, meta: { title: 'Templates' } },
            { path: 'backgrounds', name: 'admin-backgrounds', component: Backgrounds, meta: { title: 'Backgrounds' } },
            { path: 'icons', name: 'admin-icons', component: Icons, meta: { title: 'Icons' } },
            { path: 'logs', name: 'admin-logs', component: Logs, meta: { title: 'Logs' } },
            {
                path: 'families-management',
                name: 'admin-families-management',
                component: FamiliesManagement,
                meta: { title: 'Families Management' },
                props: route => ({
                    familiesType: route.query.familiesType,
                }),
            },
            {
                path: 'editor/:id',
                name: 'admin-editor',
                component: Editor,
                meta: { title: 'Editor', defaultParams: { id: 'new' } },
                props: route => ({
                    id: route.params.id || 'new',
                    clone: route.query.clone === 'true',
                    edit: route.query.edit === 'true',
                }),
            },
            { path: '*', redirect: 'templates' },
        ] as RouteConfig[]).map(config => Object.assign(config, { meta: { ...config.meta, requireAuth: true, adminRoute: true } })),
    },
];
