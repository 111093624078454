




import { Component } from 'vue-property-decorator';
import { TextWidget } from '../types/Widget';
import BaseContainer from './BaseContainer.vue';
import TextTemplate from './TextTemplate.vue';
@Component({
    components: {
        TextTemplate,
    },
})
export default class TextContainer extends BaseContainer<TextWidget> {
    get style() {
        return {
            ...this.getBaseWidgetStyles(),
            fontSize: `${this.schema.fontSize}px`,
            fontWeight: this.schema.fontWeight,
            lineHeight: this.schema.lineHeight,
            letterSpacing: this.schema.letterSpacing,
            color: this.schema.color,
            backgroundColor: this.schema.backgroundColor,
        };
    }
}
