
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CreateElement, VNode, VNodeData } from 'vue';

@Component({})
export default class FormControl extends Vue {
    @Prop({ required: false }) label!: string;

    createLabel(h: CreateElement) {
        return h('label', { attrs: { class: 'form-control__label' } }, [this.label]);
    }
    render(h: CreateElement) {
        if (this.$slots.default) {
            const [control] = this.$slots.default;
            if (['input', 'textarea'].includes(control.tag || '') && control.data) {
                control.data = {
                    ...control.data,
                    ...({ staticClass: [control.data.staticClass, 'form-control__field'].filter(Boolean).join(' ') } as VNodeData),
                };
            }
        }
        return h(
            'div',
            {
                attrs: {
                    class: 'form-control',
                },
            },
            [
                this.label && this.$slots.checkbox
                    ? h('div', { class: 'form-control__label-wrapper' }, [this.createLabel(h), this.$slots.checkbox])
                    : this.createLabel(h),
                h('div', { attrs: { class: 'form-control__body' } }, this.$slots.default),
            ]
        );
    }
}
