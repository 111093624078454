






























import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { Banner } from '../models';
import { prepareBannerDataModel, getBannerId } from '@shared/utils';
import assetsUrl from '../filters/assetsUrl';
import { throttle } from '../utils/throttle';
import TemplateRenderer from '@/packages/template-renderer/TemplateRenderer.vue';

interface Point {
    x: number;
    y: number;
}

@Component({
    components: { TemplateRenderer },
})
export default class BannerComponent extends Vue {
    @Prop({ required: false }) banner!: Banner;
    @Prop({ default: false, type: Boolean }) selected!: boolean;
    @Prop({ default: 0.25 }) size!: number;
    @Ref('box') box!: HTMLDivElement;
    gridCardHeight = 175;
    bgSize = { width: 0, height: 0 };
    moveStartPoint!: Point | null;
    prevCursor!: string;
    moving = false;
    position = { x: 0, y: 0 };
    currentMovement = { x: 0, y: 0 };
    initPosition = { x: 0, y: 0 };
    scale = 1;

    mounted() {
        this.updateBannerSize();
        window.addEventListener('resize', this.onWindowResize);
    }

    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
        document.removeEventListener('click', this.captureClick, { capture: true });
        this.removeListeners();
    }

    calculateScale() {
        return this.banner.template.width > this.banner.template.height
            ? this.getGridCardWidth() / this.banner.template.width
            : this.gridCardHeight / this.banner.template.height;
    }

    updateBannerSize() {
        if (this.box) {
            this.gridCardHeight = this.box.getBoundingClientRect().width * 0.875;
        }
        this.scale = this.calculateScale();
    }

    @Watch('size')
    onSizeChange() {
        this.$nextTick(() => {
            this.updateBannerSize();
        });
    }

    onWindowResize = throttle(this.updateBannerSize, 50);

    onMouseMove(e: MouseEvent) {
        this.currentMovement.x = this.currentMovement.x + e.movementX;
        this.currentMovement.y = this.currentMovement.y + e.movementY;
        const _isAllowedToMove = Math.abs(this.currentMovement.x - this.initPosition.x) > 30 || Math.abs(this.currentMovement.y - this.initPosition.y) > 30;
        if (this.moveStartPoint && _isAllowedToMove) {
            this.moving = true;
            this.updateChildrenOpacity();
            this.position = {
                x: parseFloat((this.position.x + e.movementX / this.scale).toFixed(2)),
                y: parseFloat((this.position.y + e.movementY / this.scale).toFixed(2)),
            };
            this.onPositionChange(this.position);
        }
    }

    updateChildrenOpacity() {
        this.box?.firstElementChild &&
            [...this.box.firstElementChild.children].forEach(child => {
                if (child instanceof HTMLElement) {
                    child.style.opacity = this.moving ? '0.3' : '1';
                }
            });
    }

    onMouseDown(event: MouseEvent) {
        if (event.button !== 0) return;
        if (this.bannerElement) {
            this.prevCursor = document.body.style.cursor;
            document.body.style.cursor = 'move';
        }
        if (this.model.backgroundPosition) {
            const [, x, y] = this.model.backgroundPosition.match(/^([^\s]+)\s([^\s]+)$/) || [];
            const parsed = [parseInt(x), parseInt(y)];
            this.position.x = parsed[0] || 0;
            this.position.y = parsed[1] || 1;
            this.currentMovement = { x: parsed[0] || 0, y: parsed[1] || 1 };
            this.initPosition = { x: parsed[0] || 0, y: parsed[1] || 1 };
        }
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        this.moveStartPoint = { x: event.x, y: event.y };
    }

    onMouseUp(event: MouseEvent) {
        if (this.bannerElement) {
            document.body.style.cursor = this.prevCursor;
        }
        this.moving = false;
        event.preventDefault();
        event.stopImmediatePropagation();
        document.addEventListener('click', this.captureClick, { capture: true });
        this.removeListeners();
        this.updateChildrenOpacity();
    }

    captureClick(event: MouseEvent) {
        if (this.moveStartPoint && (this.moveStartPoint.x - event.x !== 0 || this.moveStartPoint.y - event.y !== 0)) {
            event.preventDefault();
            event.stopPropagation();
        }
        document.removeEventListener('click', this.captureClick, { capture: true });
    }

    removeListeners() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }

    onPositionChange(point: Point) {
        this.$parent.$parent.$emit('onBackgroundMove', { point, id: getBannerId(this.banner) });
    }

    @Watch('model.backgroundImage', { immediate: true })
    onBackgroundSourceChange(newValue: string) {
        // load and cache actual resolution for image in background
        if (newValue) {
            const image = new Image();
            image.onload = () => {
                this.bgSize.width = image.width;
                this.bgSize.height = image.height;
            };
            image.src = assetsUrl(newValue);
        }
    }

    get fontSize() {
        return (
            Math.min(
                (this.getGridCardWidth() / this.banner.template.width) * 10,
                (this.gridCardHeight / this.banner.template.height) * 10
            ) + 'px'
        );
    }

    get dimension() {
        return `${this.banner.template.width} x ${this.banner.template.height}px`;
    }

    get title() {
        return this.banner.name || this.banner.template.name;
    }

    get model() {
        return prepareBannerDataModel(this.banner);
    }

    get defaults() {
        // fixed
        return {};
    }

    get bannerElement() {
        return this.box ? (this.box.firstElementChild as HTMLDivElement) : null;
    }

    getGridCardWidth() {
        return this.box ? this.box.getBoundingClientRect().width : 0;
    }
}
