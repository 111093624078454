
import { TemplateWidgets, WidgetBase } from '../types/Widget';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getCssPosition, valueOrAuto } from '@/shared/utils/style';
@Component
export default class BaseWidget<W extends TemplateWidgets | WidgetBase = WidgetBase> extends Vue {
    @Prop({ required: true, default: () => ({}) }) schema!: W;
    @Prop({ required: true }) assetsRoot!: string;
    @Prop({ required: false, default: () => ({}) }) model!: Record<string, unknown>;
    isLogo(path: string): boolean {
        return path ? /\.(gif|jpe?g|tiff|png|webp|bmp|svg)$/i.test(path) : false;
    }

    getBaseWidgetStyles<S extends WidgetBase>(shema?: S): Record<string, number | string> {
        return {
            ...getCssPosition(this.schema),
            width: valueOrAuto(shema || this.schema.width),
            height: valueOrAuto(shema || this.schema.height),
            padding: (shema || this.schema).padding || 0,
            margin: (shema || this.schema).margin || 0,
            zIndex: (shema || this.schema).zIndex || 0,
        };
    }
}
