














































import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { BannerResource } from '@models';
import EditorFiledMixin from './EditorFiledMixin.vue';
import { debounce } from '@shared/utils';
import { VueConstructor } from 'vue';
import { FamiliesType } from '@shared/models/FamiliesType';

const emptyIcon: BannerResource = {
    id: -1,
    defaultUrl: 'empty',
    name: 'empty',
};

interface PositionProps {
    width: string;
    top: string;
    left: string;
}

interface PropPopoverRef extends VueConstructor<Vue> {
    hide: () => void;
    isOpen: boolean;
}

@Component({
    model: {
        prop: 'value',
        event: 'change',
    },
})
export default class PickLogo extends EditorFiledMixin<{ value: string }> {
    @Prop({ default: () => [], type: Array }) icons!: BannerResource[];
    @Ref('popover') readonly popoverRef!: PropPopoverRef;
    hideIconPicker!: (e: Event) => void;
    family: FamiliesType | null = null;

    created() {
        this.hideIconPicker = debounce((e?: Event) => {
            // if event was triggered out of popover, hide popover
            const isScrollByDropdown = e?.target instanceof Element && e?.target.className === 'vs__dropdown-menu';
            if (this.popoverRef?.isOpen && !isScrollByDropdown) this.popoverRef.hide();
        }, 100);
        window.addEventListener('scroll', this.hideIconPicker, true);
    }

    setDefaultFamily() {
        if (this.icons && this.icons.length && !this.family && this.icons[0].family) {
            this.family = this.icons[0].family;
        }
    }

    destroyed() {
        window.removeEventListener('scroll', this.hideIconPicker, true);
    }

    mounted() {
        if (this.model.value) {
            this.family = this.selected?.family || null;
        } else {
            this.setDefaultFamily();
        }
    }

    @Watch('icons')
    onIconsChange() {
        this.setDefaultFamily();
    }

    get selected() {
        return this.icons.find(i => i.defaultUrl === this.model.value);
    }

    get url() {
        return this.selected ? this.selected.defaultUrl : '';
    }

    get families() {
        const ids = new Set();
        const res = [];
        for (const icon of this.icons) {
            if (icon.family && !ids.has(icon.family?.id)) {
                ids.add(icon.family?.id);
                res.push(icon.family);
            }
        }
        return res;
    }

    get filteredIcons() {
        return [emptyIcon].concat(this.icons.filter(i => i.family?.id === this.family?.id));
    }

    calculatePosition(dropdownList: HTMLElement, _: any, { width, top, left }: PositionProps) {
        const heightInput: number = (document.getElementsByClassName('pick-logo-overlay__select')[0] as HTMLElement).offsetHeight;

        dropdownList.style.left = left;
        dropdownList.style.width = width;
        dropdownList.style.top = `${parseInt(top) - dropdownList.offsetHeight - heightInput}px`;
        dropdownList.style.zIndex = '2000';
    }
}
