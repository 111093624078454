

































import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class TemplatesFilters extends Vue {
    @Prop({ default: 'filter' }) icon!: string;
    @Prop({ default: 'title' }) title!: string;
    @Prop({ default: () => [], type: Array }) items!: string[];
    @Model('change', { default: () => [], type: Array }) value!: string[];

    onChange(e: Event) {
        const target = e.target as HTMLInputElement;
        this.$emit('change', !target.checked ? this.value.filter(v => v !== target.value) : this.value.concat(target.value));
    }
}
