import { PageRequest, PageResult } from '@/shared/models/pagination';
import ListPageApiAdapter from './ListPageApiAdapter';
import axios from 'axios';
import endpoints from '@services/api/endpoints';
import { ListFilterParams } from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

export class FamilyAdapter implements ListPageApiAdapter<ListFilterParams, FamiliesType[]> {
    public constructor(private _endpoints: Record<string, string>) {}

    public async getPage(request: PageRequest<ListFilterParams>) {
        const page = await axios.post<PageResult<FamiliesType[]>>(this._endpoints.getPage, request);
        return page.data;
    }
}

export default new FamilyAdapter(endpoints.manageFamilies);
