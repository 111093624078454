export function throttle<F extends (...args: any[]) => any>(fn: F, wait: number) {
    let isCalled = false;

    return function(this: any, ...args: Parameters<F>): void {
        if (!isCalled) {
            fn(...args);
            isCalled = true;
            setTimeout(function() {
                isCalled = false;
            }, wait);
        }
    };
}
