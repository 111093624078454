import { PageRequest, PageResult } from '@/shared/models/pagination';
import ListPageApiAdapter from '@/services/api/adapters/ListPageApiAdapter';
import endpoints from '../../endpoints';
import { TemplateListFilterParams } from '@/modules/templates/models';
import axios from 'axios';
import { IconsFilterParams, IconsList, IconUploadRequestData } from '@/modules/admin/Icons/types';

type IconsPageRequest = PageRequest<Partial<TemplateListFilterParams>>;

export class IconsApiAdapter implements ListPageApiAdapter<TemplateListFilterParams, IconsList> {
    public constructor(private _endpoints: typeof endpoints) {}

    public async getPage(request: IconsPageRequest): Promise<PageResult<IconsList>> {
        const page = await axios.post<PageResult<IconsList>>(this._endpoints.icons.getPage, request);
        return page.data;
    }

    public upload({ file, purpose, isTemp, familyId }: IconUploadRequestData) {
        const data = new FormData();
        data.append('file', file, file.name);
        return axios.put<{ icons: IconsList[] }>(endpoints.icons.base, data, { params: { isTemp, purpose, familyId } });
    }

    public async delete(id: string) {
        await axios.delete(this._endpoints.icons.base, { data: { id: id } });
    }

    public getFilterParams() {
        return axios.get<IconsFilterParams>(this._endpoints.icons.getFilterParams).then(res => res.data);
    }
}

export default new IconsApiAdapter(endpoints);
