import axios from 'axios';
import endpoints from '../endpoints';
import { AuthApiProvider, AuthTokenResponce } from '@/services/auth/AuthApiProvider';

export class TokenApiAdapter implements AuthApiProvider {
    public constructor(private _endpoints: typeof endpoints) {}

    async getToken(userDomainName: string, password: string) {
        try {
            return await axios.get<AuthTokenResponce>(this._endpoints.token.base, { params: { userDomainName, password } });
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async refreshToken(refreshToken: string) {
        return await axios.post<AuthTokenResponce>(this._endpoints.token.base, '"' + refreshToken + '"', {
            headers: { 'Content-type': 'application/json' },
        });
    }

    async deleteToken() {
        return await axios.delete<void>(this._endpoints.token.base);
    }
}

export default new TokenApiAdapter(endpoints);
