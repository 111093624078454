/* copy from https://github.com/C-likethis123/tui-font-size-picker */

/* eslint-disable */
// @ts-ignore
// @ts-nocheck

import './index.css';

/**
 * Renders a list of font sizes for users to choose from
 * @param {Editor} editor - instance of Editor or Viewer
 */
function initDropdown(editor, preset) {
    const fontWeightValues = Array.isArray(preset) && preset.length ? preset : [400, 500, 600, 700];
    const dropdownContainer = document.createElement('div');
    const dropdown = document.createElement('ul');
    dropdown.classList.add('font-weight-dropdown');

    if (!fontWeightValues.length) {
        const option = document.createElement('li');
        option.classList.add('font-dropdown-option');
        option.textContent = 'No sizes';
        dropdown.appendChild(option);
    } else {
        fontWeightValues.forEach(fontWeight => {
            const option = document.createElement('li');

            option.classList.add('font-dropdown-option');
            option.textContent = fontWeight;
            option.value = fontWeight;
            // program what happens when option is clicked
            option.addEventListener('click', () => {
                editor.exec('changeFontWeight', fontWeight); // emit change font size event
            });
            dropdown.appendChild(option);
        });
    }
    dropdownContainer.appendChild(dropdown);
    const popup = editor.getUI().createPopup({
        header: false,
        title: null,
        content: dropdownContainer,
        className: 'fontWeightDropdownContainer',
        target: editor.getUI().getToolbar().el,
    });

    return popup;
}

/**
 * Renders an input for users to change font sizes
 * @param {Editor} editor
 */
function initFontWeightButton(editor) {
    const toolbar = editor.getUI().getToolbar();
    const fontWeightButton = document.createElement('button');

    fontWeightButton.classList.add('tui-font-weight');
    fontWeightButton.onclick = function (e) {
        e.stopPropagation();
    }
    toolbar.insertItem(-1, {
        type: 'divider',
    });
    toolbar.insertItem(-2, {
        type: 'button',
        options: {
            name: 'fontWeightPlugin',
            event: 'showDropdownWeight',
            tooltip: 'Font Weight',
            el: fontWeightButton,
        },
    });
    fontWeightButton.textContent = 'fw';

    return fontWeightButton;
}

/**
 * Initialise UI rendering logic
 * @param {Editor} editor - instance of editor
 * @param {HTMLInputElement} fontWeightButton - HTMLInputElement to enter font sizes
 * @param {HTMLDivElement} dropdown - dropdown of font sizes
 */
function initUIEvents(editor, fontWeightButton, dropdown) {
    const editorContent = editor.layout.el;
    editorContent.addEventListener('click', () => {
        if (dropdown.isShow()) {
            dropdown.hide();
            return;
        }
    })
    editor.eventManager.addEventType('showDropdownWeight');
    editor.eventManager.addEventType('hideDropdownWeight');
    editor.eventManager.listen('showDropdownWeight', () => {
        if (dropdown.isShow()) {
            dropdown.hide();
            return;
        }

        const { offsetLeft, offsetTop, offsetHeight } = fontWeightButton;

        dropdown.el.setAttribute('style', `top: ${offsetTop + offsetHeight}px; left: ${offsetLeft}px`);
        dropdown.show();
    });

    editor.eventManager.listen('hideDropdownWeight', () => {
        dropdown.hide();
    });
}

/**
 * Renders the UI of the editor
 * @param {Editor|Viewer} editor - instance of Editor or Viewer
 */
function initUI(editor, preset) {
    return {
        fontWeightButton: initFontWeightButton(editor),
        dropdown: initDropdown(editor, preset),
    };
}

/**
 * Font size plugin
 * Some code was modified from https://github.com/nhn/tui.editor/blob/master/plugins/color-syntax/src/js/index.js to learn how to make use of the TUI Editor's API to create the plugin
 * @param {Editor|Viewer} editor - instance of Editor or Viewer
 */

export default function fontWeightPlugin(editor, options) {
    const { fontWeightButton, dropdown } = initUI(editor, options.preset);
    initUIEvents(editor, fontWeightButton, dropdown);

    editor.addCommand('markdown', {
        name: 'changeFontWeight',
        exec(md, fontWeight) {
            // not yet implemented for markdown
        },
    });

    editor.addCommand('wysiwyg', {
        name: 'changeFontWeight',
        exec(wwe, fontWeight) {
            const sq = wwe.getEditor();
            const range = sq.getSelection().cloneRange();

            sq.changeFormat(
                {tag: 'SPAN', attributes: { class: 'weight', style: 'font-weight: ' + fontWeight}},
                {tag: 'SPAN', attributes: { class: 'weight'}},
                range,
            );
            sq.focus();
            editor.eventManager.emit('hideDropdownWeight');
        },
    });
}
