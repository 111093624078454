var render, staticRenderFns
import script from "./FormControl.vue?vue&type=script&lang=ts&"
export * from "./FormControl.vue?vue&type=script&lang=ts&"
import style0 from "@styles/components/form-control.css?vue&type=style&index=0&id=2f571f0a&scoped=true&lang=css&"
import style1 from "./FormControl.vue?vue&type=style&index=1&id=2f571f0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f571f0a",
  null
  
)

export default component.exports