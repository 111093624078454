







import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
@Component({
    model: {
        prop: 'selected',
        event: 'change',
    },
})
export default class Selectable extends Vue {
    @Prop({ default: 'id' }) itemKey!: string;
    @Prop({ default: () => [], type: Array }) selected!: any[];
    @Prop() items!: any[];
    @Prop({ default: true, type: Boolean }) multiple!: boolean;
    @Prop({ default: 'simple', type: String }) behavior!: 'simple' | 'advanced';
    rangeStartValue: any = null;

    isSelected(item: any) {
        return this.selected.includes(item[this.itemKey]);
    }

    getIndex(value: any) {
        return this.items.findIndex(i => i[this.itemKey] === value);
    }

    selectAll() {
        this.$emit(
            'change',
            this.items.map(i => i[this.itemKey])
        );
    }

    reset() {
        this.$emit('change', []);
    }

    @Watch('selected') onSelectedChange(value: any[]) {
        if (!this.selected.length && this.rangeStartValue) {
            this.rangeStartValue = null;
        }
    }

    selectOne(value: any) {
        this.rangeStartValue = value;
        this.$emit('change', [value]);
    }

    addToSelection(value: any) {
        const selected = new Set([...this.selected]);
        selected.has(value) ? selected.delete(value) : selected.add(value);
        this.rangeStartValue = selected.size ? value : null;
        this.$emit('change', [...selected]);
    }

    selectRange(value: any) {
        const indexes = this.items.map(s => [s[this.itemKey], this.getIndex(s[this.itemKey])]);
        const toSelect = [value, this.getIndex(value)];
        const firstRangeIndex = this.getIndex(this.rangeStartValue);
        if (firstRangeIndex === toSelect[1]) {
            return this.selectOne(toSelect[value]);
        }
        const range = indexes
            .filter(([, index]) =>
                firstRangeIndex > toSelect[1]
                    ? index <= firstRangeIndex && index >= toSelect[1]
                    : index >= firstRangeIndex && index <= toSelect[1]
            )
            .map(i => i[0]);
        this.$emit('change', [...new Set([...range, ...this.selected])]);
    }

    select(item: any, event?: MouseEvent) {
        const value = item[this.itemKey];
        if (this.multiple) {
            if (this.behavior === 'simple') {
                this.addToSelection(value);
                return;
            }
            const multiSelect = event && (event.ctrlKey || event.metaKey);
            const rangeSelect = event && event.shiftKey;
            if (multiSelect && this.selected.length) {
                this.addToSelection(value);
            } else if (rangeSelect && this.rangeStartValue) {
                this.selectRange(value);
            } else {
                this.selectOne(value);
            }
        } else {
            this.$emit('change', this.selected.includes(value) ? [] : [value]);
        }
    }
}
