







import EditPanel from '@modules/editor/components/EditPanel.vue';
import { Component, Vue } from 'vue-property-decorator';
import TemplateRenderer from './TemplateRenderer.vue';
import schemas from './testSchemas';
@Component({
    components: {
        'ad-template': TemplateRenderer,
        'edit-panel': EditPanel,
    },
})
export default class RendererTestPage extends Vue {
    schema = schemas.gray;
}
