import endpoints from '../../endpoints';
import axios, { AxiosResponse } from 'axios';
import { Template } from '@/packages/template-renderer/types/Template';
import { LayerImage } from '@/shared/models/LayerImage';

interface IEditorApiAdapter {
    uploadLayer(request: FileData): Promise<AxiosResponse<{ images: LayerImage[] }>>;
    deleteLayer(layerId: string): Promise<void>;
}
export interface FileData {
    file: File | Blob;
    name?: string;
}

export class EditorApiAdapter implements IEditorApiAdapter {
    public constructor(private _endpoints: typeof endpoints) {}

    public async uploadLayer({ file, name }: FileData) {
        const data = new FormData();
        data.append('file', file, name);
        return await axios.put<{ images: LayerImage[] }>(this._endpoints.templatesAdmin.uploadLayer, data);
    }

    public async saveTemplate(data: Template) {
        const response = await axios.post(this._endpoints.templatesAdmin.saveTemplate, data);
        return response.data;
    }

    public async deleteLayer(layerId: string) {
        await axios.delete<void>(this._endpoints.templatesAdmin.uploadLayer, { data: { ids: [layerId] } });
    }
}

export default new EditorApiAdapter(endpoints);
