export enum Sorting {
    ByName = 0,
    ByDate = 1,
    BySize = 2,
}

export enum SortingOrder {
    Desc,
    Asc,
}
