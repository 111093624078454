




import { Component } from 'vue-property-decorator';
import { LayerWidget } from '../types/Widget';
import BaseContainer from './BaseContainer.vue';
import LayerTemplate from './LayerTemplate.vue';
@Component({
    components: { LayerTemplate },
})
export default class Layer extends BaseContainer<LayerWidget> {
    get style() {
        return {
            ...this.getBaseWidgetStyles(),
            width: this.schema.width ? `${this.schema.width}px` : '100%',
            height: this.schema.height ? `${this.schema.height}px` : '100%',
            backgroundImage: `url('${this.$options.filters?.assetsUrl(this.schema.defaultValue, this.assetsRoot)}')`,
        };
    }
}
