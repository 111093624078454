







import { Vue, Component, Prop } from 'vue-property-decorator';
import { Compact } from 'vue-color';

@Component({
    components: {
        Compact,
    },
})
export default class ColorPicker extends Vue {
    @Prop({ type: String }) label!: string;
    @Prop({ default: () => ['#ffffff', '#26bb16', '#3b4747'], type: Array }) palette!: string[];
    @Prop({ default: '#3b4747', required: false, type: String }) value!: string;
}
