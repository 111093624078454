



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { TemplateWidgets, TemplateWidgetTypes } from './types/Widget';
import * as widgets from './components';
import { TemplateModel } from '@/shared/models/Template';
import { TemplateSchema } from './types/Template';

const map: Record<TemplateWidgetTypes, any> = {
    icon: widgets.Icon,
    text: widgets.Text,
    button: widgets.Button,
    layer: widgets.Layer,
};

@Component
export default class TemplateRenderer extends Vue {
    @Prop({ default: '/assets/' }) assetsRoot!: string;
    @Prop({ required: true }) model!: TemplateModel;
    @Prop({ required: true }) schema!: TemplateSchema;

    get modelFields() {
        return this.model.fields.reduce((acc, field) => {
            return Object.assign(acc, { [field.label]: field });
        }, {});
    }

    getComponent(widget: TemplateWidgets) {
        return map[widget.type];
    }
}
