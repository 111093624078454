export enum FamilyType {
    TempBackgroundFamily = 0,
    BackgroundFamily = 1,
    TemplateCollection = 2,
    IconFamily = 3,
    ButtonIconFamily = 4,
}

export type RequestForUpdateFamilyType = {
    family: {
        id: number | null;
        familyType: FamilyType;
        name: string;
    };
};
