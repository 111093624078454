export function addCssUnit(value: unknown, unit: 'px' | 'em' | '%' = 'px') {
    return `${value}${unit}`;
}
export function valueOrAuto(value: unknown) {
    return value ? addCssUnit(value) : 'auto';
}
export function getCssPosition(model: { top?: number; left?: number; bottom?: number; right?: number }) {
    return {
        top: !model.bottom ? addCssUnit(model.top) : valueOrAuto(model.top),
        left: !model.right ? addCssUnit(model.left) : valueOrAuto(model.left),
        bottom: valueOrAuto(model.bottom),
        right: valueOrAuto(model.right),
    };
}
