export default {
    manageFamilies: {
        base: '/api/family/admin',
        getPage: '/api/family/admin/page',
        usages: '/api/family/admin/usages',
    },
    icons: {
        base: '/api/icon/admin',
        getPage: '/api/icon/admin/page',
        getFilterParams: '/api/icon/admin/filterParams',
    },
    backgrounds: {
        base: '/api/background/admin',
        getPage: '/api/background/admin/page',
        getFilterParams: '/api/background/filterParams',
    },
    logs: {
        getPage: '/api/user-action/admin/page',
    },
    templates: {
        base: '/api/template',
        getPage: '/api/template/page',
        getFilterParams: '/api/template/filterParams',
        getRenderingParams: '/api/template/renderingParams',
        getTemplate: '/api/template',
        getPageAsAdmin: '/api/template/admin/page',
    },
    templatesAdmin: {
        base: '/api/template/admin',
        cloneTemplate: '/api/template/admin/clone',
        saveTemplate: '/api/template/admin',
        getPage: '/api/template/admin/page',
        uploadLayer: '/api/template/image/admin',
        getFilterParams: '/api/template/filterParams',
        getFamilies: '/api/template/list-used-families',
    },
    media: {
        base: '/api/background',
        getPage: '/api/background/page',
        getFilterParams: '/api/background/filterParams',
    },
    banners: {
        base: '/api/banner',
        getPage: '/api/banner/page',
        logDownload: '/api/banner/logDownload',
        getFilterParams: '/api/banner/filterParams',
        getEditingParams: '/api/banner/editingParams',
        generate: '/api/banner/generate',
    },
    token: {
        base: '/api/token',
    },
};
