import { PageRequest, PageResult } from '@/shared/models/pagination';
import ListPageApiAdapter from './ListPageApiAdapter';
import endpoints from '../endpoints';
import { TemplatePreview } from '@/modules/templates/models/TemplatePreview';
import { TemplateListFilterParams, TemplateListFilters } from '@/modules/templates/models';
import axios from 'axios';
import { Template } from '@/shared/models/Template';
import { parseJsonFields } from '@/shared/utils/parseJsonFileds';

type TemplatePageRequest = PageRequest<Partial<TemplateListFilterParams>>;

type EndpointsTemplates = typeof endpoints.templates;
type EndpointsAdminTemplates = typeof endpoints.templatesAdmin;
type CurrentEndpoints = EndpointsTemplates | EndpointsAdminTemplates;

export class TemplatesApiAdapter implements ListPageApiAdapter<TemplateListFilterParams, TemplatePreview> {
    public constructor(private _endpoints: CurrentEndpoints) {}

    public async getPage(request: TemplatePageRequest): Promise<PageResult<TemplatePreview>> {
        const page = await axios.post<PageResult<TemplatePreview>>(this._endpoints.getPage, request);
        return page.data;
    }

    public async getFilters() {
        return (await axios.get<TemplateListFilters>(this._endpoints.getFilterParams)).data;
    }

    public async getTemplatesWithScheme(ids: number[]) {
        const { data } = await axios.get<{ templates: Template[] }>(this._endpoints.base, {
            params: { id: ids },
        });
        return data.templates.map(t => parseJsonFields<Template>(t, { content: 'contentSchema' }));
    }
}

export default new TemplatesApiAdapter(endpoints.templates);
