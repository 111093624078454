// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseJsonFields<R extends Record<string, any>>(obj: any, extraKeysMapper: Partial<{ [key in keyof R]: keyof R }> = {}): R {
    Object.keys(obj).forEach(key => {
        const isKeyFromMapping = key in extraKeysMapper;
        const isJsonField = key.endsWith('Json') || isKeyFromMapping;
        if (isJsonField) {
            obj[isKeyFromMapping ? extraKeysMapper[key] : key.replace('Json', '')] =
                obj[key] && typeof obj[key] === 'string' ? JSON.parse(obj[key]) : {};
        }
    });
    return obj as R;
}
