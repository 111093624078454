import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@components/Layout.vue';
import Login from '@modules/login/Login.vue';

// class-component-hooks.js
import Component from 'vue-class-component';
import { AdminRoutes } from '@modules/admin/routes';
import RendererTestPage from '@/packages/template-renderer/RendererTestPage.vue';

// Register the router hooks with their names
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    ...AdminRoutes,
    {
        path: '/renderer',
        name: 'renderer',
        component: RendererTestPage,
    },
    { path: '/login', name: 'login', component: Login, meta: { tags: { title: 'Login' } } },
    {
        path: '/',
        name: 'layout',
        component: Layout,
        meta: {
            requireAuth: true,
            tags: {
                title: 'Login',
            },
        },
        children: [
            {
                path: 'templates',
                name: 'templates',
                component: () => import(/* webpackChunkName: "templates" */ '../modules/templates/Templates.vue'),
                meta: {
                    contentClass: 'content--wave-down',
                    tags: {
                        title: 'Templates',
                    },
                },
            },
            {
                path: 'media',
                name: 'media',
                component: () => import(/* webpackChunkName: "media" */ '../modules/media/Media.vue'),
                meta: {
                    contentClass: 'content--wave-up',
                    tags: {
                        title: 'Media Library',
                    },
                },
            },
            {
                path: 'editor/create',
                name: 'editor.create',
                component: () => import(/* webpackChunkName: "editor" */ '../modules/editor/Editor.vue'),
                props: route => ({ ids: route.query.ids }),
                meta: {
                    contentClass: 'content--indent-right',
                    tags: {
                        title: 'Edit Template',
                    },
                },
            },
            {
                path: 'editor/edit',
                name: 'editor.edit',
                component: () => import(/* webpackChunkName: "editor" */ '../modules/editor/Editor.vue'),
                props: route => ({ ids: route.query.ids }),
                meta: {
                    contentClass: 'content--indent-right',
                    tags: {
                        title: 'Edit Banners',
                    },
                },
            },
            {
                path: 'banners',
                name: 'banners',
                component: () => import(/* webpackChunkName: "banners" */ '../modules/banners/Banners.vue'),
                meta: {
                    tags: {
                        title: 'Banners',
                    },
                },
            },
            {
                path: '*',
                redirect: '/templates',
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const titleRecord = to.matched
        .slice()
        .reverse()
        .find(r => r.meta.tags && r.meta.tags.title);
    if (titleRecord) {
        document.title = titleRecord.meta.tags.title;
    }
    next();
});

export default router;
