import { render, staticRenderFns } from "./BackLink.vue?vue&type=template&id=e2cbd702&scoped=true&"
import script from "./BackLink.vue?vue&type=script&lang=ts&"
export * from "./BackLink.vue?vue&type=script&lang=ts&"
import style0 from "@styles/components/back-link.css?vue&type=style&index=0&id=e2cbd702&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2cbd702",
  null
  
)

export default component.exports