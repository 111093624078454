



















































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
    userDomainName = '';
    password = '';
    firstAttempt = true;
    inProgress = false;
    async login() {
        this.inProgress = true;
        this.firstAttempt = false;
        if (await this.$auth.login(this.userDomainName, this.password)) {
            this.$router.push('/');
        }
        this.inProgress = false;
    }
}
