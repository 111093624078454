




import { Vue, Component, Prop } from 'vue-property-decorator';
import { TemplateField } from '../models/TemplateFields';
import EditorTextField from './EditorTextField.vue';
import PickLogo from './PickLogo.vue';
import TueEditor from './TueEditor.vue';

@Component({ components: { EditorTextField, PickLogo, TueEditor } })
export default class EditorField extends Vue {
    @Prop() schema!: TemplateField;
    @Prop() model!: { [key: string]: any };

    get component() {
        switch (this.schema.type) {
            case 'input':
                return 'EditorTextField';
            case 'textarea':
                return 'TueEditor';
            case 'icon':
                return 'PickLogo';
            default:
                return null;
        }
    }
}
