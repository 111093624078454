












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ApiError } from '@api';
import Icon from './Icon.vue';

@Component({ components: { Icon } })
export default class ApiErrorNotification extends Vue {
    @Prop() private model!: ApiError;
    @Prop() private close!: () => void;

    private detailsOpen = false;

    private toggleDetails(): void {
        this.detailsOpen = !this.detailsOpen;
    }
}
