import { Banner } from '@/shared/models';
import { TemplateModel, TemplateModelField } from '@/shared/models/Template';
import { createHash } from '@/shared/utils';

export function updateModelFieldByTitle(model: TemplateModel, updates: TemplateModelField): TemplateModel {
    const fieldModelIndex = model?.fields?.findIndex(field => field.label === updates.label);
    if (fieldModelIndex !== -1) {
        model.fields.splice(fieldModelIndex, 1, Object.assign({}, model.fields[fieldModelIndex], updates));
    } else {
        model.fields.push(updates);
    }
    return { ...model, fields: [...model.fields] };
}

export function getModelFieldsByLabel(banner: Banner, label: string) {
    return banner.model.fields.filter(field => label === field.label);
}

export function getGroupedModelFields(banners: Banner[]) {
    const grouped: Record<string, TemplateModelField[]> = {};
    for (const banner of banners) {
        for (const field of banner.model.fields) {
            const hash = createHash(field.label);
            grouped[hash] = (grouped[hash] || []).concat(field);
        }
    }
    return grouped;
}

export function getUniqValuesByKey<T>(models: T[], key: keyof T) {
    return [...new Set(models.map(model => model[key]))];
}

export function getUniqValuesByKeys<T>(models: T[]) {
    const sets: Record<string, Set<unknown>> = {};
    models.forEach(model => {
        Object.keys(model).forEach(key => {
            sets[key] = (sets[key] || new Set()).add(model[key as keyof T]);
        });
    });
    return Object.keys(sets).reduce((acc, setKey) => {
        return Object.assign({ ...acc, [setKey]: [...sets[setKey]] });
    }, {} as Record<string, unknown[]>);
}
