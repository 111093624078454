const gray = {
    width: 768,
    height: 1024,
    colorPalette: [],
    fontSizes: [],
    backgroundImage: '',
    widgets: [
        {
            type: 'icon',
            left: 60,
            top: 42,
            width: 100,
            height: 72.2,
            defaultValue: 'icon/ptc-master-KO_GR.png',
            zIndex: 1,
            fill: '#000',
            label: 'LOGO-1',
        },
        {
            type: 'layer',
            defaultValue: 'img/768x1024.ea77ac36.png',
            zIndex: 0,
            label: 'overflow',
        },
        {
            type: 'icon',
            height: 44,
            top: 53,
            zIndex: 1,
            left: 600,
            defaultValue: 'icon/ptc-master-KO_GR.png',
            label: 'LOGO-2',
        },
        {
            type: 'text',
            padding: '5px',
            defaultValue: 'Augmented reality expedites training and knowledge transfer for frontline workers',
            fontSize: 45,
            color: 'rgb(255, 255, 255)',
            fontWeight: 700,
            letterSpacing: '0.25em',
            whiteSpace: 'pre-wrap',
            lineHeight: '45px',
            top: 135,
            left: 60,
            width: 550,
            zIndex: 1,
            label: 'Text',
        },
        {
            type: 'button',
            top: 355,
            left: 60,
            padding: '10px 10px',
            defaultValue: 'Test button inner',
            margin: '10px 10px',
            border: '1px solid green',
            color: '#fff',
            backgroundColor: 'green',
            fontSize: 34,
            zIndex: 1,
            fontWeight: 700,
            label: 'Call to action',
            icon: {
                type: 'icon',
                height: 44,
                top: 153,
                left: 400,
                defaultValue: 'icon/ptc-master-KO_GR.png',
                zIndex: 2,
                label: 'LOGO-3',
            },
        },
    ],
};
export default { gray };
