export default class Guid {
    public static NewGuid(): string {
        return Guid.guid();
    }
    public static get Empty() {
        return '00000000-0000-0000-0000-000000000000';
    }

    // generates a GUID
    private static guid(): string {
        const groups = [Guid.s4(), Guid.s4(), '-', Guid.s4(), '-', Guid.s4(), '-', Guid.s4(), '-', Guid.s4(), Guid.s4(), Guid.s4()];
        return groups.join('').toUpperCase();
    }

    // generates a guid group
    private static s4(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    private static altGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function(c) {
                const r = (Math.random() * 16) | 0;
                const v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            })
            .toUpperCase();
    }
}
