import endpoints from '../../endpoints';
import axios from 'axios';
import ListPageApiAdapter from '../ListPageApiAdapter';
import { ListFilterParams, PageRequest, PageResult, RequestForUpdateFamilyType } from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

type ManageFamiliesPageRequest = PageRequest<Partial<ListFilterParams>>;

class ManageFamiliesAdminApiAdapter implements ListPageApiAdapter<ListFilterParams, FamiliesType[]> {
    public constructor(private _adminEndpoints: typeof endpoints.manageFamilies) {}

    public async getPage(request: ManageFamiliesPageRequest) {
        const page = await axios.post<PageResult<FamiliesType[]>>(this._adminEndpoints.getPage, request);
        return page.data;
    }

    public async usages() {
        const response = await axios.get(this._adminEndpoints.usages);
        return response.data;
    }

    public async updateFamily(data: RequestForUpdateFamilyType) {
        await axios.post(this._adminEndpoints.base, data);
    }

    public async deleteFamily(id: number) {
        await axios.delete(this._adminEndpoints.base, { params: { familyId: id } });
    }
}

export default new ManageFamiliesAdminApiAdapter(endpoints.manageFamilies);
