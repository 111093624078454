














import { Component, Vue } from 'vue-property-decorator';
import { AsideMenuItem } from './AsideMenu.vue';
import { State } from 'vuex-class';
import DialogModal, { DialogModalProps } from '../Modal/DialogModal.vue';
import DialogService from '../../../services/DialogService';

@Component({})
export default class extends Vue {
    @State('menuCollapsed') menuCollapsed!: boolean;
    menuItems: AsideMenuItem[] = [
        {
            text: 'Create a Banner',
            path: '/templates',
            icon: 'picture',
        },
        // {
        //     text: 'Edit content',
        //     path: '/editor',
        //     icon: 'tune',
        //     static: true,
        // },
        {
            text: 'My Work',
            path: '/banners',
            icon: 'banner',
        },
    ];
    async logout() {
        DialogService.showEditorDialog().then(() => {
            this.$modal.show(
                DialogModal,
                { title: 'Logout', question: 'Do you really want to log out?' },
                {},
                {
                    apply: async () => {
                        await this.$auth.logOut();
                        this.$router.push('/login');
                    },
                }
            );
        });
    }
}
